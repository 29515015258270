import React, { useEffect, useState } from 'react'
import { Box, Button, Typography, Modal } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styles from './FamiliarTab.module.css'
import AvatarProfile from 'components/avatar-profile/AvatarProfile'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from 'modules/users'
import { getUserContainer } from 'container/user-module'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { fromModel, User, UserDTO } from 'modules/users/models/User'
import { ModalNewFamiliar } from './ModalNewFamiliar'
import { AppCheckbox } from 'components/app-checkbox/AppCheckbox'
import PhoneIcon from '@mui/icons-material/Phone'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import { getAppContainer, STATUS_SERVICE_KEY } from 'container/app'
import { IStatusService } from 'common/status/StatusService'
import { CustomModal } from 'components/modal/CustomModal'
import { CIRCLE_SERVICE_KEY, ICircleService } from 'modules/circle'
import { getCircleContainer } from 'container/circle-module'
import { forkJoin } from 'rxjs'

const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const statusService = getAppContainer().get<IStatusService>(STATUS_SERVICE_KEY)
const circleService = getCircleContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)

interface FamiliarTabProps {
  patient: UserDTO
}

export const FamiliarTab: React.FC<FamiliarTabProps> = ({ patient }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [openModalNewFamiliar, setOpenModalNewFamiliar] = useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)

  const [familiars, setFamiliars] = useState<UserDTO[] | undefined>()
  const [selectedFamiliar, setSelectedFamiliar] = useState<UserDTO | undefined>()
  const [checkFamiliars, setCheckFamiliars] = useState<Set<number>>(new Set())

  useEffect(() => {
    getFamiliars()
  }, [])

  const getFamiliars = () => {
    //  GET LIST FAMILIARS
    circleService.getByPatientID(patient.id).subscribe((data) => {
      if (data && data?.familiars) {
        forkJoin(data.familiars.map((i) => userService.getByID(i))).subscribe((familiarsData) => {
          const familiars = familiarsData
            .filter((u) => u !== undefined)
            .map((u) => fromModel(u as User))

          setFamiliars(familiars)
        })
      }
    })
  }

  const handleOpenNewFamiliar = () => {
    setOpenModalNewFamiliar(true)
  }

  const handleCloseNewFamiliar = () => {
    setOpenModalNewFamiliar(false)
    setSelectedFamiliar(undefined)
  }

  const handleSaveNewFamiliar = (newFamiliar: UserDTO) => {
    setOpenModalNewFamiliar(false)
    let tmp: UserDTO[] = []
    if (familiars) {
      tmp = [...familiars]
    }
    tmp.push(newFamiliar)
    setFamiliars(tmp)
  }

  const handleSelectFamiliar = (index: number) => {
    setCheckFamiliars((prevSelected) => {
      const newSelected = new Set(prevSelected)
      if (newSelected.has(index)) {
        newSelected.delete(index)
      } else {
        newSelected.add(index)
      }
      return newSelected
    })
  }

  const handleRemoveFamiliars = () => {
    setOpenDeleteModal(true)
  }

  const handleSaveFamiliars = () => {
    if (!familiars) return

    userService
      .updateWithFamiliars(patient, familiars, [], patient.language.toString())
      .subscribe((res) => {
        if (!res) return
        statusService.sendStatus({ variant: 'success' })
      })
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }
  const handleDeleteUser = () => {
    setOpenDeleteModal(false)
    setFamiliars((prevFamiliars) => {
      const newVal = prevFamiliars?.filter((_, index) => !checkFamiliars.has(index))
      return newVal ?? undefined
    })
    setCheckFamiliars(new Set())
  }

  return (
    <>
      {openModalNewFamiliar && (
        <Modal open={openModalNewFamiliar}>
          <ModalNewFamiliar
            familiar={selectedFamiliar}
            onClose={handleCloseNewFamiliar}
            saveData={handleSaveNewFamiliar}
          />
        </Modal>
      )}
      {openDeleteModal && (
        <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
          <CustomModal
            title={t('unassignFamiliar')}
            warningText={t('irreversibleUnassignFamiliarAction')}
            handleClose={handleCloseDeleteModal}
            handleSave={handleDeleteUser}
          />
        </Modal>
      )}

      <div className={styles.form}>
        <Box width={'100%'} display={'flex'} bgcolor="transparent" m="1rem">
          <Box id={'Seccion Izquierda'} className={styles.avatarContainer}>
            <AvatarProfile />
            <Box maxWidth={'100%'}>
              <Typography className={styles.userName}>
                {`${patient.firstName} ${patient.lastName}`}
              </Typography>
              <Typography className={styles.roleText}>{t('patient')}</Typography>
            </Box>
          </Box>

          <Box
            display={'flex'}
            flexGrow={1}
            id={'Seccion Derecha'}
            flexDirection={'column'}
            justifyContent={'space-between'}
          >
            {/*<Box className={styles.headerContainer}>
                  <Typography className={styles.headerTitle}>{t('relativeData')}</Typography>
                </Box>*/}

            <Box id={'Familiar Form'} width="85%">
              <Box maxWidth="100%" className={styles.headerContainer} marginTop="16px">
                <Typography className={styles.headerTitle}>{t('familiars')}</Typography>
              </Box>

              <Box className={styles.textFieldBox}>
                <Box width={'95%'}>
                  {!familiars || familiars.length === 0 ? (
                    <Typography className={styles.noFamiliarsMessage}>
                      <span className={styles.typoText}>{t('noFamiliarAdded')}</span>
                    </Typography>
                  ) : (
                    familiars.map((familiar, index) => (
                      <Box display={'flex'} key={index} id="Familiar">
                        <AppCheckbox
                          checked={checkFamiliars.has(index)}
                          onChange={() => handleSelectFamiliar(index)}
                        />
                        <Typography className={styles.typoText} width="70%">
                          {familiar.firstName} {familiar.lastName}
                        </Typography>
                        <Box display={'flex'} width={'20%'} alignItems={'center'}>
                          <PhoneIcon style={{ marginRight: '4px', color: '#474747' }} />
                          <Typography className={styles.typoText}> {familiar.phone} </Typography>
                        </Box>
                      </Box>
                    ))
                  )}
                  <Box className={styles.buttonContainer} display="flex">
                    <Button
                      className={styles.buttonAddFamiliar}
                      startIcon={<AddCircleIcon />}
                      onClick={handleOpenNewFamiliar}
                      disabled={familiars?.length === 4}
                      style={{
                        background:
                          familiars?.length === 4 ? 'gray' : 'var(--verde-topbar-y-botones)',
                      }}
                    >
                      {t('addFamiliar')}
                    </Button>
                    <Button
                      className={styles.buttonRemoveFamiliar}
                      startIcon={<CancelIcon />}
                      onClick={handleRemoveFamiliars}
                      disabled={checkFamiliars.size === 0}
                      style={{
                        background:
                          checkFamiliars.size === 0 ? 'gray' : 'var(--rojo-botones-eliminar)',
                      }}
                    >
                      {t('removeFamiliar')}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={styles.buttonContainer} display="flex">
              <Button className={styles.buttonAdd} type={'button'} onClick={handleSaveFamiliars}>
                {t('save')}
              </Button>
              <Button className={styles.buttonCancel} type={'button'} onClick={() => navigate(-1)}>
                {t('cancel')}
              </Button>
            </Box>
          </Box>
        </Box>
      </div>
    </>
  )
}
