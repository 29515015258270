import { Tabs, Tab, Box } from '@mui/material'
import { RouteProps } from 'routes/AppRouter'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import styles from './patient.module.css'
import { UserDTO } from 'modules/users/models/User'
import genericStyle from 'common/utils/generic.module.css'
import { ProfileTab } from './profile-tab/ProfileTab'
import { FamiliarTab } from './familiar-tab/FamiliarTab'
import { FormTabs } from './forms-tab/FormsTab'
import { TrainingTab } from './training-tab/TrainingTab'

interface UserNewPatientProps extends RouteProps {
  patient: UserDTO
}

export const UserNewPatient = (props: UserNewPatientProps) => {
  const { t } = useTranslation()

  const [tabIndex, setTabIndex] = useState<number>(0)
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => setTabIndex(newValue)

  return (
    <>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        classes={{ indicator: styles.customIndicator, root: styles.customRootTabs }}
      >
        <Tab
          label={<span className={styles.tabStyle}>{t('userData')}</span>}
          className={`${tabIndex === 0 ? styles.tabActive : styles.tab}`}
        />
        <Tab
          disabled={!props.id}
          label={<span className={styles.tabStyle}>{t('familiars')}</span>}
          className={tabIndex === 1 ? styles.tabActive : styles.tab}
        />
        <Tab
          disabled={!props.id}
          label={<span className={styles.tabStyle}>{t('forms')}</span>}
          className={`${tabIndex === 2 ? styles.tabActive : styles.tab}`}
        />
        <Tab
          disabled={!props.id}
          label={<span className={styles.tabStyle}>{t('trainings')}</span>}
          className={`${tabIndex === 3 ? styles.tabActive : styles.tab} ${styles.lastTab}`}
        />
      </Tabs>
      <Box
        className={genericStyle.container}
        style={{
          maxHeight: 'fit-content',
          borderTopLeftRadius: 0,
        }}
      >
        {tabIndex === 0 && <ProfileTab patient={props.patient} id={props.id} />}
        {tabIndex === 1 && <FamiliarTab patient={props.patient} />}
        {tabIndex === 2 && <FormTabs patient={props.patient} />}
        {tabIndex === 3 && <TrainingTab patient={props.patient} />}
      </Box>
    </>
  )
}
