import { v4 as uuidv4 } from 'uuid'
import { CircleWithPatient } from './CircleWithPatient'
import { emptyUserDTO, UserDTO } from '../../users/models/User'

export interface CircleWithPatientDTO {
  id: string | undefined
  patient: UserDTO
  familiars: string[] | undefined
}

export function emptyCircleWithPatientDTO() {
  return {
    id: uuidv4(),
    patient: emptyUserDTO(),
    familiars: [],
  }
}

export function fromModel(d: CircleWithPatient): CircleWithPatientDTO {
  return {
    id: d.id,
    patient: d.patient,
    familiars: d.familiars,
  }
}

export function toModel(d: CircleWithPatientDTO): CircleWithPatient {
  return new CircleWithPatient(d)
}
