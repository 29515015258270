import { Box, Button, Typography } from '@mui/material'
import styles from './ModalNewFamiliar.module.css'
import { Input } from 'pages/user-profile/userProfileStylesMui'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FAMILIAR_ROLE_ID } from 'features/users-new/constants'
import { emptyUserDTO, UserDTO } from 'modules/users/models/User'

interface ModalNewFamiliarProps {
  onClose: () => void
  saveData: (e: UserDTO) => void
  familiar?: UserDTO
}

export const ModalNewFamiliar: React.FC<ModalNewFamiliarProps> = ({
  familiar,
  onClose,
  saveData,
}) => {
  const { t } = useTranslation()
  const [newFamiliar, setNewFamiliar] = useState<UserDTO>(emptyUserDTO())

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target
    const updatedPatient = {
      ...newFamiliar,
      [id]: value,
      rolesIds: [FAMILIAR_ROLE_ID],
      isPatient: false,
    }
    setNewFamiliar(updatedPatient)
  }

  useEffect(() => {
    if (familiar) setNewFamiliar(familiar)
  }, [familiar])

  return (
    <Box className={styles.modalNewFamiliarContainer}>
      <Box className={styles.headerNewFamiliar}>
        <Typography className={styles.headerTitle}>{t('newFamiliar')}</Typography>
      </Box>
      <Box>
        <Box mb={1} className={styles.textFieldBox} display="flex" gap={2}>
          <Box flex={1}>
            <Typography className={styles.inputTitle}>{t('name')}</Typography>
            <Input
              id={'firstName'}
              name="firstName"
              className={styles.textField}
              value={newFamiliar.firstName}
              onChange={handleChange}
              type="text"
              required
              variant={'outlined'}
              size={'small'}
            />
          </Box>
          <Box flex={3}>
            <Typography className={styles.inputTitle}>{t('lastName')}</Typography>
            <Input
              id={'lastName'}
              name="lastName"
              className={styles.textField}
              value={newFamiliar.lastName}
              onChange={handleChange}
              type="text"
              required
              variant={'outlined'}
              size={'small'}
            />
          </Box>
        </Box>
        <Box mb={1} className={styles.textFieldBox} display="flex" gap={2}>
          <Box flex={1}>
            <Typography className={styles.inputTitle}>{t('email')}</Typography>
            <Input
              id={'email'}
              name="email"
              className={styles.textField}
              value={newFamiliar.email}
              type="email"
              onChange={handleChange}
              required
              variant={'outlined'}
              size={'small'}
            />
          </Box>
          <Box flex={3}>
            <Typography className={styles.inputTitle}>{t('phone')}</Typography>
            <Input
              id={'phone'}
              name="phone"
              className={styles.textField}
              value={newFamiliar.phone}
              onChange={handleChange}
              type="text"
              required
              variant={'outlined'}
              size={'small'}
            />
          </Box>
        </Box>
      </Box>
      <Box id={'Form y Buttons'} display="flex" justifyContent={'flex-end'} mt={4}>
        <Box
          className={styles.buttonContainer}
          display="flex"
          paddingLeft="40px"
          paddingBottom="5px"
        >
          <Button
            className={styles.buttonSubmit}
            type={'button'}
            onClick={() => saveData(newFamiliar)}
          >
            {t('save')}
          </Button>
          <Button className={styles.buttonCancel} type={'button'} onClick={() => onClose()}>
            {t('cancel')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
