import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users/container'
import React, { useEffect, useState } from 'react'
import { UserQuery } from '../../modules/users/models/User'
import { ItemList } from '../../common/models/ItemList'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { Pager } from '../../common/api/Query'
import { AppTable, Field } from '../../components/table'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { Search, SearchValue } from '../../components/table/types'
import { ROUTE_NEWS_WALL } from '../../routes/routes-constants'
import genericStyle from '../../common/utils/generic.module.css'
import { useNavigate } from 'react-router-dom'
import { useCircleContext } from 'common/context/CircleContext'
import { CircleWithPatientDTO } from 'modules/circle/models/CircleWithPatientDTO'
import { getCircleContainer } from 'container/circle-module'
import { CIRCLE_SERVICE_KEY, ICircleService } from 'modules/circle'

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const circleService = getCircleContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)

export function Circles() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setCircle } = useCircleContext()

  const [circles, setCircles] = useState<ItemList<CircleWithPatientDTO>>({ items: [], count: 0 })
  const [pager, setPager] = useState<Pager>({ offset: 0, limit: 20 })
  const [searcher, setSearcher] = useState<SearchValue<UserQuery>[]>([
    {
      name: 'firstName',
      label: t('search') + '...',
    },
  ])

  useEffect(() => {
    const loggedUser = loggedUserService.get()
    if (!loggedUser) {
      console.error('No logged user found')
      return
    }

    circleService.getListByFamiliarIDWithUser(loggedUser.id, loggedUser.id).subscribe((cs) => {
      console.log('Circles received:', cs)
      setCircles(cs)
      if (cs.count === 1) {
        const circle = cs.items[0]
        handleSelect(circle)
      }
    })
  }, [])

  useEffect(() => {
    const loggedUser = loggedUserService.get()
    if (!loggedUser) {
      console.error('No logged user found')
      return
    }

    circleService.getListByFamiliarIDWithUser(loggedUser.id, loggedUser.id).subscribe((cs) => {
      const searchTerm = searcher.find((s) => s.name === 'firstName')?.value?.toLowerCase()
      const filteredItems = cs.items.filter((item) => {
        return searchTerm
          ? item.patient.firstName.toLowerCase().includes(searchTerm) ||
              item.patient.lastName.toLowerCase().includes(searchTerm)
          : true
      })

      setCircles({
        items: filteredItems as CircleWithPatientDTO[],
        count: filteredItems.length,
      })
    })
  }, [pager, searcher])

  const handleSelect = (i: CircleWithPatientDTO) => {
    const loggedUser = loggedUserService.get()
    if (!loggedUser) return

    const circle: CircleWithPatientDTO = {
      id: i.id,
      patient: i.patient,
      familiars: i.familiars || [loggedUser.id],
    }

    setCircle(circle)
    localStorage.setItem('circle', JSON.stringify(circle))
    navigate(ROUTE_NEWS_WALL)
  }

  const search: Search<UserQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<UserQuery>[]) => setSearcher(svs),
  }

  const fields: Field<CircleWithPatientDTO>[] = [
    {
      name: 'patient',
      label: t('fullName'),
      renderFunc: (_, item) => (
        <div onClick={() => handleSelect(item)} style={{ cursor: 'pointer' }}>
          {`${item.patient.firstName} ${item.patient.lastName}`}
        </div>
      ),
    },
    {
      name: 'patient',
      label: t('birthDate'),
      renderFunc: (_, item) =>
        item.patient.dob ? new Date(item.patient.dob).toLocaleDateString() : '',
    },
  ]

  return (
    <Box className={genericStyle.pageContainer}>
      <AppTable items={circles.items} rowKeyField="id" fields={fields} search={search} />
    </Box>
  )
}
