import {
  Box,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
} from '@mui/material'
import styles from './MedicationListModal.module.scss'
import { Header } from 'components/header/Header'
import React from 'react'
import { useTranslation } from 'react-i18next'
import close from 'assets/articles/delete.svg'
import { UserTreatmentWithTreatmentNameDTO } from 'modules/treatments/models/UserTreatmentWithTreatmentNameDTO'
import editIcon from 'assets/user-table/edit.svg'
import { getUserContainer } from 'container/user-module'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
export interface Schedule extends UserTreatmentWithTreatmentNameDTO {
  frequency: string
}

type MedicationListModalProps = {
  treatments: UserTreatmentWithTreatmentNameDTO[]
  onClose: () => void
}

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export const MedicationListModal: React.FC<MedicationListModalProps> = ({
  onClose,
  treatments,
}) => {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const isProfessional = loggedUser?.roles.includes('med')
  const handleEditTrain = (v: UserTreatmentWithTreatmentNameDTO) => {}
  const handleToggleSelection = (v: UserTreatmentWithTreatmentNameDTO) => {}

  return (
    <Box className={styles.container}>
      <Box className={styles.content}>
        <Header labelStyle={styles.header} label={t('medication')} icon={close} onClick={onClose} />
        {!treatments ||
          (treatments.length === 0 && (
            <Typography className={styles.noFamiliarsMessage}>
              <span className={styles.typoText}>{t('noMedicationAdded')}</span>
            </Typography>
          ))}
        <List style={{ display: 'flex', flexDirection: 'column' }}>
          {treatments.map((t) => {
            const labelId = `checkbox-list-label-${t.userTreatment.id}`
            // const isSelected =
            //   selectedTraining.findIndex(
            //     (item) => item.userTraining.id === t.userTreatment.id
            //   ) !== -1

            return (
              <ListItem
                secondaryAction={
                  isProfessional && (
                    <IconButton edge="end" aria-label="comments" onClick={() => handleEditTrain(t)}>
                      <img src={editIcon} alt="edit" />
                    </IconButton>
                  )
                }
                key={t.userTreatment.id}
                disablePadding
              >
                <ListItemButton role={undefined} onClick={() => handleToggleSelection(t)} dense>
                  {isProfessional && (
                    <ListItemIcon>
                      <Checkbox
                        style={{ color: 'var(--verde-topbar-y-botones)' }}
                        edge="start"
                        tabIndex={-1}
                        disableRipple
                        // checked={isSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                  )}
                  <p className={styles.textTrainingList} id={labelId}>
                    {t.name}
                  </p>
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
      </Box>
    </Box>
  )
}
