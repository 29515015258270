import { BoolQueryParam } from '../../../common/api/Query'
import { v4 as uuidv4 } from 'uuid'
import { UserGender } from '../enums/UserGender'
import { LangType } from '../../content/enums/LangType'

export interface UserDTO {
  id: string
  firstName: string
  lastName: string
  gender: UserGender
  email: string
  dob: Date
  language: LangType
  historyRecordNumber: number
  diagnosisSummary: string
  diagnosis: string
  isPatient: boolean
  isActive: boolean
  assignedID: string
  hasUsedEpico: boolean
  roles: string[]
  rolesIds: string[]
  patologies: string[]
  phone?: string
}

export function emptyUserDTO() {
  return {
    id: uuidv4(),
    firstName: '',
    lastName: '',
    gender: UserGender.Female,
    email: '',
    dob: new Date(),
    language: LangType.Spanish,
    historyRecordNumber: 0,
    diagnosisSummary: '',
    diagnosis: '',
    isPatient: true,
    isActive: true,
    assignedID: '',
    hasUsedEpico: false,
    roles: [],
    rolesIds: [],
    patologies: [],
    phone: '',
  }
}

export class User {
  private readonly _id: string
  private readonly _isPatient: boolean

  private _firstName: string
  private _lastName: string
  private _gender: UserGender
  private _email: string
  private _dob: Date
  private _language: LangType
  private _historyRecordNumber: number
  private _diagnosisSummary: string
  private _diagnosis: string
  private _isActive: boolean
  private _assignedID: string
  private _hasUsedEpico: boolean
  private readonly _roles: string[]
  private readonly _rolesIds: string[]
  private readonly _patologies: string[]
  private _phone?: string

  constructor(p: UserDTO) {
    this._id = p.id
    this._firstName = p.firstName
    this._lastName = p.lastName
    this._gender = p.gender
    this._email = p.email
    this._dob = p.dob
    this._language = p.language
    this._historyRecordNumber = p.historyRecordNumber
    this._diagnosisSummary = p.diagnosisSummary
    this._diagnosis = p.diagnosis
    this._isPatient = p.roles.includes('patient')
    this._isActive = p.isActive
    this._assignedID = p.assignedID
    this._hasUsedEpico = p.hasUsedEpico
    this._roles = p.roles
    this._rolesIds = p.rolesIds
    this._patologies = p.patologies
    this._phone = p.phone
  }

  get roles(): string[] {
    return this._roles
  }

  get rolesIds(): string[] {
    return this._rolesIds
  }

  get patologies(): string[] {
    return this._patologies
  }
  get id(): string {
    return this._id
  }

  get isPatient(): boolean {
    return this._isPatient
  }

  get firstName(): string {
    return this._firstName
  }

  set firstName(value: string) {
    this._firstName = value
  }

  get lastName(): string {
    return this._lastName
  }

  set lastName(value: string) {
    this._lastName = value
  }

  get gender(): UserGender {
    return this._gender
  }

  set gender(value: UserGender) {
    this._gender = value
  }

  get email(): string {
    return this._email
  }

  set email(value: string) {
    this._email = value
  }

  get dob(): Date {
    return this._dob
  }

  set dob(value: Date) {
    this._dob = value
  }

  get language(): LangType {
    return this._language
  }

  set language(value: LangType) {
    this._language = value
  }

  get historyRecordNumber(): number {
    return this._historyRecordNumber
  }

  set historyRecordNumber(value: number) {
    this._historyRecordNumber = value
  }

  get diagnosisSummary(): string {
    return this._diagnosisSummary
  }

  set diagnosisSummary(value: string) {
    this._diagnosisSummary = value
  }

  get diagnosis(): string {
    return this._diagnosis
  }

  set diagnosis(value: string) {
    this._diagnosis = value
  }

  get isActive(): boolean {
    return this._isActive
  }

  set isActive(value: boolean) {
    this._isActive = value
  }

  get assignedID(): string {
    return this._assignedID
  }

  set assignedID(value: string) {
    this._assignedID = value
  }

  get hasUsedEpico(): boolean {
    return this._hasUsedEpico
  }

  set hasUsedEpico(value: boolean) {
    this._hasUsedEpico = value
  }

  get phone(): string | undefined {
    return this._phone
  }

  set phone(value: string | undefined) {
    this._phone = value
  }

  toDTO(): UserDTO {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      gender: this.gender,
      email: this.email,
      dob: this.dob,
      language: this.language,
      historyRecordNumber: this.historyRecordNumber,
      diagnosisSummary: this.diagnosisSummary,
      diagnosis: this.diagnosis,
      isPatient: this.roles.includes('patient'),
      isActive: this.isActive,
      assignedID: this.assignedID,
      hasUsedEpico: this.hasUsedEpico,
      roles: this.roles,
      rolesIds: this.rolesIds,
      patologies: this.patologies,
      phone: this.phone,
    }
  }
}

export interface UserQuery {
  id: string
  ids: string[]
  isPatient: BoolQueryParam
  firstName: string
  lastName: string
  historyRecordNumber: number
  assignedID: string
  isActive: BoolQueryParam
  hasUsedEpico: boolean
}

export function toModel(d: UserDTO): User {
  return new User(d)
}

export function fromModel(d: User): UserDTO {
  return {
    id: d.id,
    firstName: d.firstName,
    lastName: d.lastName,
    gender: d.gender,
    email: d.email,
    dob: d.dob,
    language: d.language,
    historyRecordNumber: d.historyRecordNumber,
    diagnosisSummary: d.diagnosisSummary,
    diagnosis: d.diagnosis,
    isPatient: d.roles.includes('patient'),
    isActive: d.isActive,
    assignedID: d.assignedID,
    hasUsedEpico: d.hasUsedEpico,
    roles: d.roles,
    rolesIds: d.rolesIds,
    patologies: d.patologies,
    phone: d.phone,
  }
}
