import React, { useEffect, useState } from 'react'
import { ViewProps } from '../calendar/types'
import style from './WeekView.module.css'
import dayjs from 'dayjs'
import { dateToDateString, getMonday } from '../../common/utils/date'
import { Event as E } from '../../modules/calendar/models/Event'
import { Query, QueryParam } from '../../common/api/Query'
import { Box, Button, Modal, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { getUserContainer } from '../../container/user-module'
import { capitalize, reduceString } from '../../common/utils/strings'
import { CalendarModal } from '../calendar/CalendarModal'
import { ICircleService } from '../../modules/users/services/CircleService'
import { CIRCLE_SERVICE_KEY, LOGGED_USER_SERVICE_KEY } from '../../modules/users/container'
import { getCalendarContainer } from '../../container/calendar-module'
import { EventService } from '../../modules/calendar/services/EventService'
import { EVENT_SERVICE_KEY } from '../../modules/calendar'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { useTranslation } from 'react-i18next'
import addIcon from '../../assets/calendar/add.svg'
import { ROUTE_CALENDAR_FORM, ROUTE_CREATE } from '../../routes/routes-constants'
import genericStyle from '../../common/utils/generic.module.css'
import { useIsOpenMenuLeftContext } from '../../common/utils/isOpenMenuLeftContext'
import { EventCategory } from '../../modules/calendar/enums/EventCategory'
import { Tooltip } from '@mui/material'
import reviewIcon from '../../assets/events_icons/bola-revision.svg'
import dateIcon from '../../assets/calendar/cita.svg'
import vaccineIcon from '../../assets/events_icons/bola-vacunas.svg'
import adviceIcon from '../../assets/events_icons/bola-consejos.svg'
import landmarkIcon from '../../assets/events_icons/bola-hitos.svg'
import trainingIcon from '../../assets/calendar/ejercicio.svg'
import formIcon from '../../assets/events_icons/evento-formulario.svg'
import othersIcon from '../../assets/events_icons/bola-otros.svg'
import medicationIcon from '../../assets/dashboard/treatmentIconEvent.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { useCircleContext } from '../../common/context/CircleContext'

const calendarContainer = getCalendarContainer()
const eventService = calendarContainer.get<EventService>(EVENT_SERVICE_KEY)

const hours = (): string[] => {
  const hourRange = []
  for (let i = 7; i < 23; i++) {
    if (i < 10) {
      hourRange.push('0' + i + ':00')
    } else {
      hourRange.push(i + ':00')
    }
  }
  return hourRange
}

type WeekViewProps = { id: string | undefined } & ViewProps

type WeekEvent = {
  event: E
}
const loggedUserService = getUserContainer().get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
export function WeekViewHome(props: WeekViewProps) {
  const { t } = useTranslation()
  const [selectedEvent, setSelectedEvent] = useState<E>()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [events, setEvents] = useState<E[]>([])
  const [monday, setMonday] = useState<Date>(getMonday(props.selectedDate))
  const [date, setDate] = useState<Date>(props.selectedDate || new Date())
  const loggedUser = loggedUserService.get()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { openMenuLeft } = useIsOpenMenuLeftContext()
  const navigate = useNavigate()
  const location = useLocation()
  const { circle } = useCircleContext()

  useEffect(() => {
    const finishDate = new Date(monday)
    finishDate.setDate(finishDate.getDate() + 7)

    const userId = circle?.patient?.id || loggedUser?.id || ''

    eventService
      .getFilteredList(
        new Query({
          query: [
            new QueryParam<E>('startDate', monday.toISOString()),
            new QueryParam<E>('finishDate', finishDate.toISOString()),
          ],
        }),
        userId
      )
      .subscribe(
        (res) => {
          setEvents([...res.items])
        },
        (error) => {
          console.error('WeekViewHome - Error fetching events:', error) // Log para capturar errores
        }
      )
  }, [monday, circle]) // Añadir "circle" como dependencia

  useEffect(() => {
    const tmpMonday = getMonday(props.selectedDate)
    tmpMonday.getTime() !== monday.getTime() && setMonday(tmpMonday)
  }, [props.selectedDate])

  const handleCloseModal = () => setOpenModal(false)

  const editEvent = (e: E) => {
    navigate(`${ROUTE_CALENDAR_FORM}/${e.id}`, { state: { date: date } })
    setOpenModal(false)
  }

  const deleteEvent = (e: E) => {
    if (e.id) {
      eventService.delete(e.id).subscribe(() => {
        setDate(new Date(date))
        setIsLoading(!isLoading)
      })
    }
    setOpenModal(false)
  }

  const getIcon = (type: EventCategory) => {
    switch (type) {
      case EventCategory.Reviews:
        return (
          <Tooltip title={t('reviews') || 'reviews'}>
            <img src={reviewIcon} alt={'reviews'} />
          </Tooltip>
        )

      case EventCategory.Medication:
        return (
          <Tooltip title={t('treatment') || 'treatment'}>
            <img src={medicationIcon} alt={'treatment'} />
          </Tooltip>
        )

      case EventCategory.Vaccines:
        return (
          <Tooltip title={t('vaccine') || 'vaccine'}>
            <img src={vaccineIcon} alt={'vaccine'} />
          </Tooltip>
        )

      case EventCategory.Appointment:
        return (
          <Tooltip title={t('medicalappointment') || 'medicalappointment'}>
            <img src={dateIcon} alt={'medicalappointment'} />
          </Tooltip>
        )

      case EventCategory.Advices:
        return (
          <Tooltip title={t('advices') || 'advices'}>
            <img src={adviceIcon} alt={'advices'} />
          </Tooltip>
        )

      case EventCategory.Landmarks:
        return (
          <Tooltip title={t('landmarks') || 'landmarks'}>
            <img src={landmarkIcon} alt={'landmarks'} />
          </Tooltip>
        )

      case EventCategory.Training:
        return (
          <Tooltip title={t('training') || 'training'}>
            <img src={trainingIcon} alt={'training'} />
          </Tooltip>
        )
      case EventCategory.Forms:
        return (
          <Tooltip title={t('form') || 'form'}>
            <img src={formIcon} alt={'training'} />
          </Tooltip>
        )

      default:
        return (
          <Tooltip title={t('others') || 'others'}>
            <img src={othersIcon} alt={'others'} />
          </Tooltip>
        )
    }
  }

  const getColorByCategory = (category: number) => {
    let color

    switch (category) {
      case 1:
        color = '#ADB84E'
        break

      case 2:
        color = '#f37e31'
        break

      case 3:
        color = '#d2617e'
        break

      case 4:
        color = '#eadf33'
        break

      case 5:
        color = '#3fbfcc'
        break

      case 6:
        color = '#ADB84E'
        break

      case 7:
        color = '#815abb'
        break

      case 8:
        color = '#9b8c58'
        break
      default:
        color = '#959595'
        break
    }
    return color
  }
  const handlerClick = (event: E) => {
    setSelectedEvent(event)
    setOpenModal(true)
  }
  function WeekEvent(props: WeekEvent): JSX.Element {
    let duration
    if (
      dayjs(props.event.finishDate).format('DD').toString() ===
      dayjs(props.event.startDate).format('DD').toString()
    ) {
      duration =
        Number(dayjs(props.event.finishDate).format('HH')) -
        Number(dayjs(props.event.startDate).format('HH'))
    }

    return (
      <>
        {props.event.startDate.getHours() === props.event.finishDate.getHours() ? (
          <Box
            className={style.eventNotSameDay}
            onClick={() => handlerClick(props.event)}
            style={{
              backgroundColor: getColorByCategory(props.event.eventCategory),
            }}
          >
            <div className={style.iconEventCategoryNotSameDay}>
              {getIcon(props.event.eventCategory)}
            </div>
            <h3 className={style.eventTitleNotSameDay}>
              {dayjs(props.event.startDate).format('HH:mm') +
                ' ' +
                reduceString(props.event.title, 15)}
            </h3>
          </Box>
        ) : (
          <Box
            className={style.event}
            onClick={() => handlerClick(props.event)}
            style={{
              ['--duration-height' as any]: duration,
              ['--col' as any]: getColorByCategory(props.event.eventCategory),
              backgroundColor: getColorByCategory(props.event.eventCategory),
            }}
          >
            <div className={style.iconEventCategory}>{getIcon(props.event.eventCategory)}</div>
            <h3 className={style.eventTitle}>
              {dayjs(props.event.startDate).format('HH:mm') +
                ' ' +
                reduceString(props.event.title, 15)}
            </h3>
          </Box>
        )}
      </>
    )
  }

  const weekDays = (): Date[] => {
    let datesRange = [monday]

    for (let i = 1; i < 7; i++) {
      let newDate = new Date(monday)
      newDate.setDate(monday.getDate() + i)
      datesRange.push(newDate)
    }
    return datesRange
  }

  const createEvent = () =>
    navigate(`${ROUTE_CALENDAR_FORM}/${ROUTE_CREATE}`, {
      state: { date: date, currentUrl: location.pathname },
    })

  const patientName = circle?.patient
    ? `${circle.patient.firstName} ${circle.patient.lastName}`
    : ''

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: openMenuLeft ? '50px' : '',
      }}
    >
      <div
        style={{
          maxWidth: openMenuLeft ? 'calc(100% - 250px)' : 'calc(100% - 300px)',
          paddingTop: 50,
        }}
      >
        {patientName && (
          <h3 style={{ marginBottom: '20px', color: '#a9ba37', fontWeight: 'bold' }}>
            {t('patient')}: {patientName}
          </h3>
        )}
        <div className={style.container}>
          <Table
            sx={{
              borderRadius: '27px',
              border: 'none',
              background: 'transparent',
              width: 'auto',
              '& td': {
                borderStyle: 'dotted',
                borderColor: '#ADB84E',
                '&:nth-child(odd)': {
                  backgroundColor: 'rgba(173,184,78,0.06)',
                },
                '&:last-child': {
                  borderRightWidth: 1,
                  borderRightStyle: 'dotted',
                  borderColor: '#ADB84E',
                },
                '&:first-child': {
                  borderLeftWidth: 1,
                  borderLeftStyle: 'dotted',
                  borderColor: '#ADB84E',
                },
              },
              '& tr': {
                borderStyle: 'dotted',
                borderColor: '#ADB84E',
                '&:nth-child(odd)': {
                  backgroundColor: 'rgba(173,184,78,0.06)',
                },
              },
              '& th': {
                borderBottomStyle: 'dotted',
                borderColor: '#ADB84E',
                padding: '14px 0 5px 0',
              },
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: openMenuLeft ? '' : '100%',
                marginLeft: openMenuLeft ? 30 : '',
                marginRight: openMenuLeft ? 30 : '',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    className={style.weekHeader}
                    style={{
                      visibility: 'hidden',
                      width: '37px',
                      padding: '0',
                      margin: '0',
                    }}
                  >
                    <div className={style.hour} style={{ marginRight: 18 }}></div>
                  </TableCell>
                  {weekDays().map((d, i) => (
                    <TableCell className={style.weekHeader}>
                      <div className={`${i === 0 ? style.day1 : ''}`} key={dateToDateString(d)}>
                        <div
                          className={
                            new Date(d).getDay() === new Date().getDay()
                              ? style.weekNoDay
                              : style.weekDay
                          }
                        >
                          <div className={style.weekDayNumber}>{dayjs(d).format('DD')}</div>
                          <div className={style.weekDayName}>
                            {window.innerWidth > 480
                              ? capitalize(dayjs(d).locale(navigator.language).format('dddd'))
                              : capitalize(dayjs(d).locale(navigator.language).format('dd'))}
                          </div>
                        </div>
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {hours().map((h, i) => (
                  <TableRow>
                    <TableCell key={i} className={style.hourRow}>
                      <div style={{ maxHeight: '42px' }} className={style.hour} key={i}>
                        {h}
                      </div>
                    </TableCell>
                    {weekDays().map((d) => (
                      <TableCell className={style.eventRow} key={String(d) + String(h)}>
                        <div className={style.containerEvent}>
                          {events.map((event: E) =>
                            dayjs(d).format('DD').toString() ===
                              dayjs(event.startDate).format('DD').toString() &&
                            dayjs(event.startDate).format('DD').toString() ===
                              dayjs(event.finishDate).format('DD').toString() &&
                            h.split(':')[0] === dayjs(event.startDate).format('HH').toString() ? (
                              <WeekEvent event={event} key={event.id} />
                            ) : (
                              dayjs(d).format('DD').toString() ===
                                dayjs(event.startDate).format('DD').toString() &&
                              dayjs(event.startDate).format('DD').toString() !==
                                dayjs(event.finishDate).format('DD').toString() &&
                              h.split(':')[0] ===
                                dayjs(event.startDate).format('HH').toString() && (
                                <Box
                                  className={style.eventNotSameDay}
                                  onClick={() => handlerClick(event)}
                                  style={{
                                    backgroundColor: getColorByCategory(event.eventCategory),
                                  }}
                                >
                                  <div className={style.iconEventCategoryNotSameDay}>
                                    {getIcon(event.eventCategory)}
                                  </div>
                                  <h3 className={style.eventTitleNotSameDay}>
                                    {dayjs(event.startDate).format('HH:mm') +
                                      ' ' +
                                      reduceString(event.title, 15)}
                                  </h3>
                                </Box>
                              )
                            )
                          )}
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </div>
            <div className={style.buttonAddAppointmentContainer}>
              <Button onClick={createEvent} className={style.buttonAddAppointment}>
                <img src={addIcon} alt="add" className={style.addIcon} />
                {t('addAppointment')}
              </Button>
            </div>
          </Table>
          {selectedEvent && (
            <Modal open={openModal} className={style.eventModal} onClose={handleCloseModal}>
              <CalendarModal
                event={selectedEvent}
                handleClose={handleCloseModal}
                handleEdit={editEvent}
                handleRemove={deleteEvent}
              />
            </Modal>
          )}
        </div>
      </div>
    </div>
  )
}
