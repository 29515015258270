import { getNotificationContainer } from '../../container/notification-module'
import { NotificationService } from '../../modules/notifications/services/NotificationService'
import { MESSAGES_SERVICE_KEY, NOTIFICATION_SERVICE_KEY } from '../../modules/notifications'
import { MessageService } from '../../modules/notifications/services/MessageService'
import { getMessengerContainer } from '../../container/messenger-module'
import { ConversationService } from '../../modules/messenger/services/ConversationService'
import { CONVERSATION_SERVICE_KEY } from '../../modules/messenger'
import { getUserContainer } from '../../container/user-module'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { Notification as N } from '../../modules/notifications/models/Notification'
import { Message } from '../../modules/notifications/models/Message'
import styles from './List.module.css'
import { ActivityWidget } from './ActivityWidget'
import { Query, QueryParam } from '../../common/api/Query'
import { Conversation } from '../../modules/messenger/models/Conversation'
import { TransportType } from '../../common/enums/TransportType'
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab'
import { forkJoin, Observable } from 'rxjs'
import { emptyUserDTO, User, UserDTO } from '../../modules/users/models/User'
import { WeekViewHome } from './WeekViewHome'
import { FORM_SERVICE_KEY, IFormService } from '../../modules/forms'
import { getFormContainer } from '../../container/form-module'
import { useCircleContext } from '../../common/context/CircleContext'
import genericStyle from '../../common/utils/generic.module.css'

const notificationContainer = getNotificationContainer()
const notificationService = notificationContainer.get<NotificationService>(NOTIFICATION_SERVICE_KEY)
const messageService = notificationContainer.get<MessageService>(MESSAGES_SERVICE_KEY)
const messengerContainer = getMessengerContainer()
const conversationsService = messengerContainer.get<ConversationService>(CONVERSATION_SERVICE_KEY)
const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

const notificationsPerPage = 7

type NewsWallProps = {
  id?: string
  selectedDate?: Date
}

const formService = getFormContainer().get<IFormService>(FORM_SERVICE_KEY)

const NotificationTimelineItem = ({
  notification,
  author,
}: {
  notification: N
  author: string
}) => {
  return (
    <TimelineItem>
      <TimelineOppositeContent style={{ padding: 0 }} />
      <TimelineSeparator>
        <TimelineConnector style={{ backgroundColor: '#e5e5e5' }} />
        <TimelineDot
          variant={'outlined'}
          className={styles.timelineDot}
          style={{ borderColor: '#a9ba37' }}
        />
        <TimelineConnector style={{ backgroundColor: '#e5e5e5' }} />
      </TimelineSeparator>
      <TimelineContent>
        <ActivityWidget
          id={notification.id}
          source={notification.notType}
          title={notification.title}
          description={notification.message}
          creator={author}
          date={notification.createdAt}
          url={''}
          status={notification.sendingStatus}
        />
      </TimelineContent>
    </TimelineItem>
  )
}

export const NewsWall = (props: NewsWallProps) => {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const { circle } = useCircleContext()

  const [conversationCollection, setConversationCollection] = useState<Conversation[]>([])
  const [notifications, setNotifications] = useState<N[]>([])
  const [notificationMessages, setNotificationMessages] = useState<Map<string, Message>>(new Map())
  const [authors, setAuthors] = useState<Map<string, string>>(new Map())
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [page, setPage] = useState<number>(1)
  const [user, setUser] = useState<UserDTO>(emptyUserDTO())
  const [doctor, setDoctor] = useState<UserDTO>(emptyUserDTO())

  useEffect(() => {
    if (isLoading && loggedUser?.id && circle?.patient?.id) {
      notificationService
        .getFilteredList(
          new Query({
            pager: { offset: (page - 1) * notificationsPerPage, limit: notificationsPerPage },
            query: [
              new QueryParam<N>('recipientID', circle.patient.id),
              new QueryParam<N>('transportType', TransportType.App),
            ],
            sort: [{ field: 'createdAt', desc: true }],
          })
        )
        .subscribe((res) => {
          setNotifications(res.items.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime()))
          setIsLoading(false)
        })
    }
  }, [isLoading, circle, loggedUser])

  useEffect(() => {
    if (!notifications.length) {
      return
    }
    const authorsTmp = new Map()
    getUsers(notifications.map((n) => n.senderID)).subscribe((res) => {
      res.filter((u) => u).map((u) => authorsTmp.set(u.id, u.firstName))
      setAuthors(authorsTmp)
    })
  }, [notifications])

  useEffect(() => {
    if (!loggedUser) return
    userService.getByID(loggedUser?.id).subscribe((res) => {
      if (!res) return
      setUser(res)
    })
  }, [loggedUser])

  useEffect(() => {
    if (!user) return
    userService.getByID(user.assignedID).subscribe((res) => {
      if (!res) return
      setDoctor(res)
    })
  }, [user])

  const getUsers = (ids: string[]): Observable<User[]> =>
    forkJoin(ids.map((id) => userService.getByID(id))) as unknown as Observable<User[]>

  const getMessages = (ids: string[]): Observable<Message[]> =>
    forkJoin(ids.map((id) => messageService.getByID(id))) as unknown as Observable<Message[]>

  return (
    <>
      <div
        style={{ display: 'flex', gap: '20px', marginTop: 70 }}
        className={genericStyle.pageContainer}
      >
        <WeekViewHome
          id={'1'}
          selectedDate={props.selectedDate ?? new Date()}
          handlerEdit={() => {}}
          handlerRemove={() => {}}
        />
        {!isLoading && (
          <div className={styles.wallContainer}>
            <div className={styles.notificationContainer}>
              <h2>{t('activity')}</h2>
              {notifications.length ? (
                <div>
                  {notifications.map((n) => (
                    <NotificationTimelineItem
                      key={n.id}
                      notification={n}
                      author={authors.get(n.senderID) || ''}
                    />
                  ))}
                </div>
              ) : (
                <h2 className={styles.textBox}>{t('noNotifications')}</h2>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  )
}
