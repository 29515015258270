import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, Modal, Typography } from '@mui/material'
import { AppCheckbox } from 'components/app-checkbox/AppCheckbox'
import { CustomTooltip } from 'components/custom-tooltip/CustomTooltip'
import seeIcon from 'assets/articles/see.svg'
import editIcon from 'assets/viewPatients/edit.svg'
import styles from '../patient.module.css'
import { useTranslation } from 'react-i18next'
import AvatarProfile from 'components/avatar-profile/AvatarProfile'
import { UserDTO } from 'modules/users/models/User'
import { FORM_NAME } from 'features/users/constants'
import { MedicationListModal } from './MedicationListModal'
import { FormType, formTypesIds } from 'modules/forms/enums/FormType'
import { FeedingFormModal } from 'features/form-generator/feeding-form/FeedingFormModal'
import { UserTreatmentWithTreatmentNameDTO } from 'modules/treatments/models/UserTreatmentWithTreatmentNameDTO'
import { IUserTreatmentService } from 'modules/treatments/services/UserTreatmentService'
import { getContentContainer } from 'container/treatment-module'
import { USER_TREATMENT_SERVICE_KEY } from 'modules/treatments'
import { Query, QueryParam } from 'common/api/Query'
import { UserTreatmentQuery } from 'modules/treatments/models/UserTreatment'
import { getFormContainer } from 'container/form-module'
import { UserFormService } from 'modules/forms/services/UserFormService'
import { USER_FORM_SERVICE_KEY } from 'modules/forms'
import { useNavigate } from 'react-router-dom'
import {
  emptyTemplateUserRecurrenceDTO,
  TemplateUserRecurrenceDTO,
} from 'modules/forms/models/UserFormDTO'
import { ConductFormModal } from 'features/form-generator/conduct-form/ConductFormModal'
import { TemplateUserRecurrence } from 'modules/forms/models/TemplateUserRecurrence'

interface Form {
  name: string
  value: string
}

interface FormTabsProps {
  patient: UserDTO
}

const forms: Form[] = [
  { name: FORM_NAME.FOOD, value: 'food' },
  { name: FORM_NAME.CONDUCT, value: 'conduct' },
  { name: FORM_NAME.MEDICATION, value: 'medication' },
]

const userFormService = getFormContainer().get<UserFormService>(USER_FORM_SERVICE_KEY)
const userTreatmentService = getContentContainer().get<IUserTreatmentService>(
  USER_TREATMENT_SERVICE_KEY
)

export const FormTabs: React.FC<FormTabsProps> = ({ patient }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [selectedForms, setSelectedForms] = useState<Set<string>>(new Set())
  const [openSeeMedicationModal, setOpenSeeMedicationModal] = useState<boolean>(false)
  const [openFeedingModal, setOpenFeedingModal] = useState<boolean>(false)
  const [openConductModal, setOpenConductModal] = useState<boolean>(false)
  const [recurrences, setRecurrences] = useState<TemplateUserRecurrenceDTO[] | null>(null)
  const [treatments, setTreatments] = useState<UserTreatmentWithTreatmentNameDTO[]>([])

  // Conduct Form Modal
  const [numberTimesDays, setNumberTimesDays] = useState<number | string | undefined>()
  const [frequency, setFrequency] = useState<number | string | undefined>()

  // Feeding Form Modal
  // const [userForms, setUserForms] = useState<UserFormWithTemplateName[]>([])

  useEffect(() => {
    if (!patient.id) return

    // this should list the conduct and feeding saved data
    userFormService.getListTemplateUserRecurrenceByUserID(patient.id).subscribe((response) => {
      if (!response) return
      console.log('tiaturbina', response.items)
      setRecurrences(response.items)

      if (response.items.length > 0) {
        const conduct_template = response.items.find(
          (f) => f.templateID === formTypesIds()[FormType.Conduct]
        )

        if (conduct_template) {
          const match = conduct_template.recurrence.match(/0 (\d+)-(\d+)\/(\d+) \* \* \*/)
          if (match) {
            const [, , , freq] = match.map(Number)
            if (24 % freq === 0) {
              setNumberTimesDays(24 / freq)
              setFrequency('')
            } else {
              setNumberTimesDays('')
              setFrequency(freq)
            }
          }
        }
      }
    })

    // get treatments (medication)
    userTreatmentService
      .getListWithTreatmentNameByUserID(
        new Query({
          query: [new QueryParam<UserTreatmentQuery>('userID', patient.id)],
        })
      )
      .subscribe((res) => {
        setTreatments(res.items)
      })
  }, [patient])

  useEffect(() => {
    // show selected checkbox
    const types = formTypesIds()
    const tmp = new Set<string>()
    if (recurrences?.some((v) => v.templateID === types[FormType.Conduct])) {
      tmp.add(FORM_NAME.CONDUCT)
    }
    if (recurrences?.some((v) => types[FormType.Feeding].includes(v.templateID))) {
      tmp.add(FORM_NAME.FOOD)
    }
    setSelectedForms(tmp)
  }, [recurrences])

  const handleRecurrenceFeeding = (
    templateType: FormType,
    recurrence: string,
    formIndex: number
  ) => {
    try {
      const templateID = formTypesIds()[templateType].split(',')[formIndex]
      setRecurrences((prevRecurrences) => {
        const newRecurrences = prevRecurrences ? [...prevRecurrences] : []
        const index = newRecurrences.findIndex((re) => re.recurrence === recurrence)
        if (index !== -1) {
          newRecurrences.splice(index, 1)
        } else {
          newRecurrences.push(emptyTemplateUserRecurrenceDTO(patient.id, templateID, recurrence))
        }
        return newRecurrences
      })
    } catch (er) {
      console.log(er)
    }
  }

  const handleRecurrenceConduct = (recurrence?: string) => {
    setRecurrences((prevRecurrences) => {
      const templateID = formTypesIds()[FormType.Conduct]
      const newRecurrences = prevRecurrences ? [...prevRecurrences] : []
      const index = newRecurrences.findIndex((re) => re.templateID === templateID)
      if (index !== -1 || !recurrence) {
        newRecurrences.splice(index, 1)
      }
      if (recurrence) {
        newRecurrences.push(emptyTemplateUserRecurrenceDTO(patient.id, templateID, recurrence))
      }
      return newRecurrences
    })
  }

  const handleSelectedForms = (name: string) => {
    setSelectedForms(new Set(name))
    if (name === FORM_NAME.CONDUCT) {
      handleOpenConductModal()
    } else {
      handleOpenFeedingModal()
    }
  }

  const handleOpenSeeMedicationModal = () => {
    setOpenSeeMedicationModal(true)
  }

  const handleCloseSeeMedicationModal = () => {
    setOpenSeeMedicationModal(false)
  }

  const handleCloseFeedingModal = () => {
    setOpenFeedingModal(false)
  }

  const handleOpenFeedingModal = () => {
    setOpenFeedingModal(true)
  }

  const handleCloseConductModal = () => {
    setOpenConductModal(false)
  }

  const handleOpenConductModal = () => {
    setOpenConductModal(true)
  }

  const onSaveForms = () => {
    if (!recurrences) return

    console.log(recurrences)
    // const parseIt = recurrences.map((r) => ({
    //   id: r.id,
    //   templateID: r.templateID,
    //   userID: r.userID,
    //   recurrence: r.recurrence,
    // }))
    // console.log(parseIt)

    userFormService.addTemplatesUserRecurrenceByUserID(patient.id, recurrences).subscribe(() => {
      console.log('stored data yay')
    })
  }

  return (
    <>
      {openSeeMedicationModal && (
        <Modal open={openSeeMedicationModal}>
          <MedicationListModal treatments={treatments} onClose={handleCloseSeeMedicationModal} />
        </Modal>
      )}
      {openConductModal && (
        <Modal open={openConductModal}>
          <ConductFormModal
            handleRecurrence={handleRecurrenceConduct}
            onClose={handleCloseConductModal}
            userNumberTimesDays={numberTimesDays}
            userFrequency={frequency}
          />
        </Modal>
      )}
      {openFeedingModal && (
        <Modal open={openFeedingModal}>
          <FeedingFormModal
            handleRecurrence={handleRecurrenceFeeding}
            onClose={handleCloseFeedingModal}
            recurrences={recurrences}
          />
        </Modal>
      )}
      <Box className={styles.form}>
        <Box width={'100%'} display={'flex'} m="1rem">
          <Box id={'Seccion Izquierda'} className={styles.avatarContainer}>
            <AvatarProfile />
            <Box maxWidth={'100%'}>
              <Typography className={styles.userName}>
                {`${patient.firstName} ${patient.lastName}`}
              </Typography>
              <Typography className={styles.roleText}>{t('patient')}</Typography>
            </Box>
          </Box>
          <Box
            display={'flex'}
            flexGrow={1}
            id={'Listado formularios'}
            flexDirection={'column'}
            justifyContent={'space-between'}
          >
            <Box>
              <Box maxWidth="100%" className={styles.headerContainer}>
                <Typography className={styles.headerTitle}>{t('forms')}</Typography>
              </Box>
              <Grid
                mt={2}
                container
                spacing={2}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                {forms.map((form, index) => (
                  <Grid
                    key={form.name}
                    item
                    xs={12}
                    sm={index === 2 ? 12 : 6}
                    md={index === 2 ? 12 : 6}
                    lg={index === 2 ? 12 : 6}
                    container
                    alignItems="center"
                  >
                    <Grid
                      item
                      display="flex"
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      {form.name === FORM_NAME.MEDICATION ? (
                        <AppCheckbox
                          checked={selectedForms.has(form.name)}
                          value={form.name}
                          onChange={() => handleSelectedForms(form.name)}
                          style={{ justifyContent: 'flex-start', visibility: 'hidden' }}
                        />
                      ) : (
                        <AppCheckbox
                          checked={selectedForms.has(form.name)}
                          value={form.name}
                          onChange={() => handleSelectedForms(form.name)}
                          style={{ justifyContent: 'flex-start' }}
                        />
                      )}

                      <Typography className={styles.checkboxLabel}>{t(form.value)}</Typography>
                      <Box display="flex" justifyContent="center">
                        {form.name === FORM_NAME.MEDICATION ? (
                          <CustomTooltip title={t('see')} placement="right">
                            <img
                              className={styles.iconEdit}
                              src={seeIcon}
                              alt="edit"
                              style={{ visibility: treatments ? 'inherit' : 'hidden' }}
                              onClick={handleOpenSeeMedicationModal}
                            />
                          </CustomTooltip>
                        ) : (
                          <CustomTooltip title={t('edit')} placement="right">
                            <img
                              className={styles.iconEdit}
                              src={editIcon}
                              alt="edit"
                              onClick={() => handleSelectedForms(form.name)}
                            />
                          </CustomTooltip>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box id={'Form y Buttons'} display="flex" justifyContent={'flex-end'} mb={4}>
              <Box
                className={styles.buttonContainer}
                display="flex"
                paddingLeft="40px"
                paddingBottom="5px"
              >
                <Button className={styles.buttonSubmit} type={'button'} onClick={onSaveForms}>
                  {t('save')}
                </Button>
                <Button
                  className={styles.buttonCancel}
                  type={'button'}
                  onClick={() => navigate(-1)}
                >
                  {t('cancel')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
