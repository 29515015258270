import { RouteProps } from 'routes/AppRouter'
import { ProfesionalTable as ProfesionalTableView } from 'features/profesionals'
import React from 'react'
import { useTitleHeaderContext } from 'common/utils/TitleHeaderContext'
import { useParams } from 'react-router-dom'

export function ProfesionalTable(props: RouteProps) {
  const title = props.title || ''
  const { setTitle } = useTitleHeaderContext()

  setTitle(title)

  return (
    <>
      <ProfesionalTableView />
    </>
  )
}
