import i18n from 'i18next'

export enum EventType {
  Article = 1,
  Resource = 2,
  Event = 3,
}

export const eventType = (): Record<EventType, string> => ({
  [EventType.Resource]: i18n.t('Resource'),
  [EventType.Article]: i18n.t('Article'),
  [EventType.Event]: i18n.t('Event'),
})
