import i18n from '../../../i18n'

export enum FormType {
  Conduct = 0,
  Feeding,
  Medication,
  Training,
}

export const formTypes = (): Record<FormType, string> => ({
  [FormType.Conduct]: 'Conduct',
  [FormType.Feeding]: 'Feeding Tracking',
  [FormType.Medication]: 'Medication',
  [FormType.Training]: 'Training',
})

export const formTypesIds = (): Record<FormType, string> => ({
  [FormType.Conduct]: '8cde6b01-a499-49cf-91f2-84e06cd56e5b',
  [FormType.Feeding]:
    '2ef89045-0644-41b7-8e21-f8a28c2a9d4b,6510a39b-879c-4bf6-af8f-fdf311d8cd01,e8b6c287-94be-47fd-ac21-0158658719ec',
  [FormType.Medication]: 'aa28f672-6287-4362-92a1-6b3b258dadad',
  [FormType.Training]: '', // no use
})

export enum FeedType {
  Breakfast = 0,
  Lunch,
  Dinner,
}

export const feedTypes = (): Record<FeedType, string> => ({
  [FeedType.Breakfast]: i18n.t('Breakfast'),
  [FeedType.Lunch]: i18n.t('Lunch'),
  [FeedType.Dinner]: i18n.t('Dinner'),
})
