import { Familiar } from '../../hooks/useFamiliars'
import { v4 as uuidv4 } from 'uuid'

export const NOTIFICATIONS = ['food', 'medication', 'conduct', 'training']

export const PROFESSIONAL_ROLE_ID = '89deecef-9da3-4c3e-bc26-243e491ccc7c'
export const PATIENT_ROLE_ID = '89deecef-9da3-4c3e-bc26-243e491ccc7b'
export const FAMILIAR_ROLE_ID = '89deecef-9da3-4c3e-bc26-243e491ccc7d'

export const initialFamiliars: Familiar = {
  id: uuidv4(),
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  role: '',
  notification: [],
}

export const FORM_NAME = {
  FOOD: 'Feeding Tracking',
  CONDUCT: 'Conduct',
  MEDICATION: 'Medication',
  TRAINING: 'Training',
}
