import { Box, Alert, Typography } from '@mui/material'
import { Input } from 'pages/user-profile/userProfileStylesMui'
import { RouteProps } from 'routes/AppRouter'
import genericStyle from '../../common/utils/generic.module.css'
import { useTranslation } from 'react-i18next'
import React, { ChangeEvent, useEffect, useState } from 'react'
import styles from './CreateProfessional.module.css'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import AvatarProfile from '../../components/avatar-profile/AvatarProfile'
import { emptyUserDTO, UserDTO } from '../../modules/users/models/User'
import { UserTreatmentWithTreatmentNameDTO } from '../../modules/treatments/models/UserTreatmentWithTreatmentNameDTO'
import { PROFESSIONAL_ROLE_ID } from './constants'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from 'modules/users'
import { getUserContainer } from 'container/user-module'
import { ROUTE_PROFESIONALS, ROUTE_USERS_CREATE } from 'routes/routes-constants'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { getAppContainer, STATUS_SERVICE_KEY } from 'container/app'
import { IStatusService } from 'common/status/StatusService'

interface CreateProfesionalProps extends RouteProps {
  professional: UserDTO
}

export interface Schedule extends UserTreatmentWithTreatmentNameDTO {
  frequency: string
}

const statusService = getAppContainer().get<IStatusService>(STATUS_SERVICE_KEY)
const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export const CreateProfessional = (props: CreateProfesionalProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const loggedUser = loggedUserService.get()
  const [message, setMessage] = useState<string>('')

  const [newProfessional, setNewProfessional] = useState<UserDTO>(emptyUserDTO())

  useEffect(() => {
    if (props.professional) {
      setNewProfessional(props.professional)
    }
  }, [props.professional])

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault()
    if (!props.professional) {
      userService.add(newProfessional).subscribe(() => {
        statusService.sendStatus({ variant: 'success' })
        navigate(ROUTE_PROFESIONALS)
      })
    } else {
      userService.update(newProfessional).subscribe(() => {
        statusService.sendStatus({ variant: 'success' })
        navigate(ROUTE_PROFESIONALS)
      })
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target
    const updatedPatient = {
      ...newProfessional,
      [id]: value,
      rolesIds: [PROFESSIONAL_ROLE_ID],
      isPatient: false,
      assignedID: loggedUser?.id ?? '',
    }
    setNewProfessional(updatedPatient)
  }

  return (
    <>
      <Box
        className={genericStyle.container}
        style={{
          maxHeight: 'fit-content',
          borderTopLeftRadius: 0,
        }}
      >
        <form className={styles.form} id={'editUser'}>
          <Box width={'100%'} display={'flex'} bgcolor="transparent" m="1rem">
            <Box id={'Seccion Izquierda'} className={styles.avatarContainer}>
              <AvatarProfile />
              <Box maxWidth={'100%'}>
                <Typography className={styles.userName}>
                  {`${props.professional.firstName} ${props.professional.lastName}`}
                </Typography>
                <Typography className={styles.roleText}>
                  {t(props.professional.roles[0])}
                </Typography>
              </Box>
            </Box>
            <Box
              display={'flex'}
              flexGrow={1}
              id={'Seccion Derecha'}
              flexDirection={'column'}
              justifyContent={'space-between'}
            >
              <Box id={'Professional Form'}>
                <Box style={{ maxWidth: '100%' }} className={styles.headerContainer}>
                  <Typography className={styles.headerTitle}>{t('professionalData')}</Typography>
                </Box>
                <Box mb={1} className={styles.textFieldBox}>
                  <Box marginRight={'20px'} width={'50%'}>
                    <Typography className={styles.inputTitle}>{t('name')}</Typography>
                    <Input
                      id={'firstName'}
                      key={'firstName'}
                      name="firstName"
                      className={styles.textField}
                      value={newProfessional.firstName}
                      type="text"
                      required={true}
                      variant={'outlined'}
                      size={'small'}
                      onChange={handleChange}
                    />
                  </Box>
                  <Box marginRight={'20px'} width={'50%'}>
                    <Typography className={styles.inputTitle}>{t('lastName')}</Typography>
                    <Input
                      id={'lastName'}
                      label={''}
                      key={'lastName'}
                      name="lastName"
                      className={styles.textField}
                      value={newProfessional.lastName}
                      type="text"
                      required={true}
                      variant={'outlined'}
                      size={'small'}
                      onChange={handleChange}
                    />
                  </Box>
                </Box>
                <Box mb={1} className={styles.textFieldBox}>
                  <Box marginRight={'20px'} width={'50%'}>
                    <Typography className={styles.inputTitle}>{t('email')}</Typography>
                    <Input
                      id={'email'}
                      label={''}
                      key={'email'}
                      name="email"
                      className={styles.textField}
                      value={newProfessional.email}
                      type="email"
                      required={true}
                      variant={'outlined'}
                      size={'small'}
                      onChange={handleChange}
                    />
                  </Box>
                  <Box marginRight={'20px'} width={'50%'}>
                    <Typography className={styles.inputTitle}>{t('phone')}</Typography>
                    <Input
                      id={'phone'}
                      label={''}
                      key={'phone'}
                      name="phone"
                      className={styles.textField}
                      value={newProfessional.phone}
                      type="phone"
                      required={true}
                      variant={'outlined'}
                      size={'small'}
                      onChange={handleChange}
                    />
                  </Box>
                </Box>
                <Box mb={1} className={styles.textFieldBox}>
                  <Box marginRight={'20px'} width={'50%'}>
                    <Typography className={styles.inputTitle}>{t('role')}</Typography>
                    <Typography className={styles.typoText} sx={{ paddingLeft: '14px' }}>
                      {t('med')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box id={'Form y Buttons'} display="flex" justifyContent={'flex-end'} mb={4}>
                <Box
                  className={styles.buttonContainer}
                  display="flex"
                  paddingLeft="40px"
                  paddingBottom="5px"
                >
                  <Button className={styles.buttonSubmit} type={'button'} onClick={handleSave}>
                    {t('save')}
                  </Button>
                  <Button
                    className={styles.buttonCancel}
                    type={'button'}
                    onClick={() => navigate(-1)}
                  >
                    {t('cancel')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </form>
        {message && (
          <Box mb={3}>
            <Alert className={styles.message} severity="success" key="message" id="message">
              {t(message)}
            </Alert>
          </Box>
        )}
      </Box>
    </>
  )
}
