import { Box } from '@mui/material'
import React, { useState } from 'react'
import { Header } from 'components/header/Header'
import styles from './FeedingForm.module.scss'
import { useTranslation } from 'react-i18next'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import { AppCheckbox } from 'components/app-checkbox/AppCheckbox'
import { FormType } from 'modules/forms/enums/FormType'
import checkIcon from 'assets/viewPatients/check.svg'
import uncheckIcon from 'assets/viewPatients/uncheck.svg'
import { Recurrence } from 'modules/forms/models/FormDTO'
import { TemplateUserRecurrenceDTO } from 'modules/forms/models/UserFormDTO'

interface FeedingFormModalProps {
  handleRecurrence: (templateType: FormType, recurrence: string, index: number) => void
  onClose: () => void
  recurrences: TemplateUserRecurrenceDTO[] | null
}

const BREAKFAST_RECURRENCE = '0 6 * * *'
const LUNCH_RECURRENCE = '0 12 * * *'
const DINNER_RECURRENCE = '0 19 * * *'

export const FeedingFormModal: React.FC<FeedingFormModalProps> = ({
  handleRecurrence,
  onClose,
  recurrences,
}) => {
  const { t } = useTranslation()

  const saveRecurrence = () => {
    onClose()
  }

  return (
    <Box className={styles.container}>
      <Box className={styles.content}>
        <Header labelStyle={styles.header} label={t('feedingForm')} />
        <Box width={'80%'} mt={2}>
          <Box display={'flex'} alignItems={'center'} mb={4}>
            <AppCheckbox
              icon={<img src={uncheckIcon} />}
              checkedIcon={<img src={checkIcon} />}
              checked={recurrences?.some((re) => re.recurrence === BREAKFAST_RECURRENCE)}
              onChange={() => handleRecurrence(FormType.Feeding, BREAKFAST_RECURRENCE, 0)}
            />
            <p className={styles.text}>{t('breakfast')}</p>
          </Box>
          <Box display={'flex'} alignItems={'center'} mb={4}>
            <AppCheckbox
              icon={<img src={uncheckIcon} />}
              checkedIcon={<img src={checkIcon} />}
              checked={recurrences?.some((re) => re.recurrence === LUNCH_RECURRENCE)}
              onChange={() => handleRecurrence(FormType.Feeding, LUNCH_RECURRENCE, 1)}
            />
            <p className={styles.text}>{t('lunch')}</p>
          </Box>
          <Box display={'flex'} alignItems={'center'}>
            <AppCheckbox
              icon={<img src={uncheckIcon} />}
              checkedIcon={<img src={checkIcon} />}
              checked={recurrences?.some((re) => re.recurrence === DINNER_RECURRENCE)}
              onChange={() => handleRecurrence(FormType.Feeding, DINNER_RECURRENCE, 2)}
            />
            <p className={styles.text}>{t('dinner')}</p>
          </Box>
        </Box>
        <Box mt={8} className={styles.buttons} display="flex" justifyContent="center" gap={1}>
          <AppButton
            theme={ButtonTheme.NewPrimary}
            type={'submit'}
            label={t('save')}
            handler={saveRecurrence}
          />
          {/* <AppButton
            theme={ButtonTheme.NewSecondary}
            type={'button'}
            label={t('cancel')}
            handler={onClose}
          /> */}
        </Box>
      </Box>
    </Box>
  )
}
