import { Query } from '../../../common/api/Query'

export type TemplateUserRecurrenceProps = {
  id: string
  templateID: string
  userID: string
  recurrence: string
}

export class TemplateUserRecurrence {
  private readonly _id: string
  private readonly _templateID: string
  private readonly _userID: string
  private readonly _recurrence: string

  constructor(p: TemplateUserRecurrenceProps) {
    this._id = p.id
    this._templateID = p.templateID
    this._userID = p.userID
    this._recurrence = p.recurrence
  }

  get id(): string {
    return this._id
  }

  get templateID(): string {
    return this._templateID
  }

  get userID(): string {
    return this._userID
  }

  get recurrence(): string {
    return this._recurrence
  }
}

export class TemplateUserRecurrenceQuery extends Query<TemplateUserRecurrence> {}
