import { Container, IInit } from '../../../common/container/Container'
import { CircleContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { catchError, map } from 'rxjs/operators'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { prepareURL } from '../../../common/api/http-helpers'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Circle } from '../models/Circle'
import { CircleDTO, toModel } from '../models/CircleDTO'
import { CircleWithPatient } from '../models/CircleWithPatient'
import { CircleWithPatientDTO } from '../models/CircleWithPatientDTO'
import { toModel as toModelWithPatient } from '../models/CircleWithPatientDTO'

export interface ICircleApi extends IInit {
  getByID(id: string): Observable<Circle | undefined>

  getByPatientID(patientID: string): Observable<Circle | undefined>

  getFilteredList(q: Query<Circle>): Observable<ItemList<Circle>>

  getListByFamiliarIDWithUser(
    familiarID: string,
    userID: string
  ): Observable<ItemList<CircleWithPatient>>

  add(e: CircleDTO): Observable<Circle | undefined>

  update(e: CircleDTO): Observable<Circle | undefined>

  delete(id: string): Observable<boolean>
}

export class CircleApi implements ICircleApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as CircleContainerConfig).moduleFullUrl + '/circle'
  }

  getListByFamiliarIDWithUser(
    familiarID: string,
    userID: string
  ): Observable<ItemList<CircleWithPatient>> {
    return this._httpClient
      .get<ItemList<CircleWithPatient>>({
        url: `${this._url}/familiar/${familiarID}`,
        headers: new Map([['userID', userID]]),
      })
      .pipe(
        map<ItemList<CircleWithPatientDTO>, ItemList<CircleWithPatient>>((dto) => {
          const itemList = emptyList<CircleWithPatient>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModelWithPatient(d))
          console.log('itemList', itemList)
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<CircleWithPatient>())
        })
      )
  }

  add(e: CircleDTO): Observable<Circle | undefined> {
    return this._httpClient.post<Circle>({ url: this._url, body: e }).pipe(
      map<CircleDTO, Circle>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<Circle | undefined> {
    return this._httpClient.get<Circle>({ url: `${this._url}/${id}` }).pipe(
      map<CircleDTO, Circle>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getByPatientID(patientID: string): Observable<Circle | undefined> {
    return this._httpClient.get<Circle>({ url: `${this._url}/patient/${patientID}` }).pipe(
      map<CircleDTO, Circle>((dto) => {
        return toModel(dto)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredList(q: Query<Circle>): Observable<ItemList<Circle>> {
    return this._httpClient
      .post<ItemList<Circle>>({ url: prepareURL(`${this._url}/list`, q) })
      .pipe(
        map<ItemList<CircleDTO>, ItemList<Circle>>((dto) => {
          const itemList = emptyList<Circle>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Circle>())
        })
      )
  }

  update(e: CircleDTO): Observable<Circle | undefined> {
    return this._httpClient.put<Circle>({ url: this._url, body: e }).pipe(
      map<CircleDTO, Circle>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }
}
