import { NOTIFICATIONS_MODULE } from '../modules/notifications'
import { MESSENGER_MODULE } from '../modules/messenger'
import { CALENDAR_MODULE } from '../modules/calendar'
import { FORMS_MODULE } from '../modules/forms'
import { FILES_MODULE } from '../modules/files'
import { ROLES_MODULE, USERS_MODULE } from '../modules/users'
import { CONTENT_MODULE } from '../modules/content'
import { PATIENT_CALENDAR_MODULE } from '../modules/patient-calendar-config'
import { VACCINES_MODULE } from '../modules/vaccines'
import { TRAINING_MODULE } from '../modules/training'
import { STATISTICS_MODULE, WEARABLE_MODULE, INTENSITY_MODULE } from '../modules/statistics'
import { TREATMENT_MODULE } from '../modules/treatments'
import { PATOLOGY_MODULE } from '../modules/patologies'
import { EXERCISEALGORITHM_MODULE } from '../modules/exerciseAlgorithm'
import { MEDIA_MODULE } from '../modules/media'
import { CIRCLE_MODULE } from '../modules/circle/container'

// const apiURL = 'http://localhost:8000'
const apiURL = process.env.REACT_APP_BACKEND_URL

export const moduleURLs = {
  [NOTIFICATIONS_MODULE]: `${apiURL}/notifications`,
  [MESSENGER_MODULE]: `${apiURL}/messenger`,
  [CALENDAR_MODULE]: `${apiURL}/calendar`,
  [FORMS_MODULE]: `${apiURL}/forms`,
  [FILES_MODULE]: `${apiURL}/files`,
  [MEDIA_MODULE]: `${apiURL}/media`,
  [USERS_MODULE]: `${apiURL}/users`,
  [ROLES_MODULE]: `${apiURL}/users/roles`,
  [CONTENT_MODULE]: `${apiURL}/content`,
  [PATIENT_CALENDAR_MODULE]: `${apiURL}/patient-calendar-config`,
  [VACCINES_MODULE]: `${apiURL}/vaccines`,
  [TRAINING_MODULE]: `${apiURL}/training`,
  [STATISTICS_MODULE]: `${apiURL}/wearable`,
  [WEARABLE_MODULE]: `${apiURL}/userWearable`,
  [TREATMENT_MODULE]: `${apiURL}/treatments`,
  [INTENSITY_MODULE]: `${apiURL}/userIntensity`,
  [PATOLOGY_MODULE]: `${apiURL}/patologies`,
  [EXERCISEALGORITHM_MODULE]: `${apiURL}/exerciseAlgorithm`,
  [CIRCLE_MODULE]: `${apiURL}/circle`,
}
