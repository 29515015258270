import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Modal,
  Typography,
} from '@mui/material'
import styles from '../patient.module.css'
import { useTranslation } from 'react-i18next'
import AvatarProfile from 'components/avatar-profile/AvatarProfile'
import { UserDTO } from 'modules/users/models/User'
import { AssignTrainingFormModal } from 'features/form-generator/training-form/AssignTrainingFormModal'
import {
  UserTrainingWithTrainingNameDTO,
  toModel,
} from 'modules/training/models/UserTrainingWithTrainingNameDTO'
import { UserTrainingWithTrainingName } from 'modules/training/models/UserTrainingWithTrainingName'
import { UserTrainingDTO } from 'modules/training/models/UserTrainingDTO'
import styleList from './TrainingTab.module.scss'
import { Query } from 'common/api/Query'
import { getTrainingContainer } from 'container/training-module'
import { IUserTrainingService } from 'modules/training/services/UserTrainingService'
import { USER_TRAINING_SERVICE_KEY } from 'modules/training'
import cancelIcon from 'assets/user-table/removeTraining.svg'
import { useNavigate } from 'react-router-dom'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import editIcon from 'assets/user-table/edit.svg'
import { getAppContainer, STATUS_SERVICE_KEY } from 'container/app'
import { IStatusService } from 'common/status/StatusService'
import { forkJoin } from 'rxjs'

interface FormTabsProps {
  patient: UserDTO
}

const userTrainingService =
  getTrainingContainer().get<IUserTrainingService>(USER_TRAINING_SERVICE_KEY)
const statusService = getAppContainer().get<IStatusService>(STATUS_SERVICE_KEY)

export const TrainingTab: React.FC<FormTabsProps> = ({ patient }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [editTraining, setEditTraining] = useState<UserTrainingWithTrainingName | undefined>()
  const [trainingSelectedWithUserAndNameTraining, setTrainingSelectedWithUserAndNameTraining] =
    React.useState<UserTrainingWithTrainingNameDTO>()

  const [trainingList, setTrainingList] = React.useState<UserTrainingWithTrainingName[]>([])
  const [originalTrainingList, setOriginalTrainingList] = React.useState<
    UserTrainingWithTrainingName[]
  >([])
  const [selectedTraining, setSelectedTraining] = React.useState<UserTrainingWithTrainingName[]>([])
  const [openAssignTraining, setOpenAssignTraining] = useState<boolean>(false)

  const handleCloseAssignTraining = () => {
    setOpenAssignTraining(false)
  }

  const handleOpenAssignTraining = () => {
    setOpenAssignTraining(true)
    setEditTraining(undefined)
    setSelectedTraining([])
  }

  useEffect(() => getTrainings(), [])

  useEffect(() => {
    if (trainingSelectedWithUserAndNameTraining) {
      const index = checkIfRepeatingID([
        ...trainingList,
        trainingSelectedWithUserAndNameTraining as UserTrainingWithTrainingName,
      ])
      if (index !== -1) {
        // Reemplazar el objeto repetido con el más reciente
        const newTrainingList = [...trainingList]
        newTrainingList[index] = toModel(trainingSelectedWithUserAndNameTraining)
        setTrainingList(newTrainingList)
      } else {
        setTrainingList([
          ...trainingList,
          trainingSelectedWithUserAndNameTraining as UserTrainingWithTrainingName,
        ])
      }
    }
  }, [trainingSelectedWithUserAndNameTraining])

  const getTrainings = () => {
    userTrainingService
      .getListWithTrainingNameByUserID(
        new Query({
          query: [
            {
              name: 'userID',
              value: patient.id,
            },
          ],
        })
      )
      .subscribe((response) => {
        setOriginalTrainingList(response.items)
        setTrainingList(response.items)
      })
  }

  const checkIfRepeatingID = (userTraining: UserTrainingWithTrainingName[]) => {
    const ids: { [key: string]: number } = {}
    for (let i = 0; i < userTraining.length; i++) {
      const obj = userTraining[i]
      if (ids[obj.userTraining.id] !== undefined) {
        return ids[obj.userTraining.id] // Devuelve el índice del ID repetido
      }
      ids[obj.userTraining.id] = i
    }
    return -1
  }

  const handleToggleSelection = (training: UserTrainingWithTrainingName) => {
    const selectedIndex = selectedTraining.findIndex(
      (item) => item.userTraining.id === training.userTraining.id
    )
    let newSelected: UserTrainingWithTrainingName[] = []

    if (selectedIndex === -1) {
      newSelected = [...selectedTraining, training]
    } else {
      newSelected = selectedTraining.filter(
        (item) => item.userTraining.id !== training.userTraining.id
      )
    }

    setSelectedTraining(newSelected)
  }

  const handleEditTrain = (training: UserTrainingWithTrainingName) => {
    setEditTraining(training)
    setOpenAssignTraining(true)
  }

  const handleRemoveTraining = () => {
    const updatedList = trainingList.filter((training) =>
      selectedTraining.some((selected) => selected.userTraining.id === training.userTraining.id)
    )
    console.log(updatedList)
    forkJoin(updatedList.map((m) => userTrainingService.delete(m.userTraining.id))).subscribe(
      () => {
        getTrainings()
      }
    )
  }

  const handleSave = () => {
    const userTrainingDTOArray: UserTrainingDTO[] = trainingList.map((training) => ({
      id: training.userTraining.id,
      userID: patient.id,
      trainingID: training.userTraining.trainingID,
      startDate: training.userTraining.startDate,
      finishDate: training.userTraining.finishDate,
      frecuency: training.userTraining.frecuency,
      series: training.userTraining.series,
      minReps: training.userTraining.minReps,
      maxReps: training.userTraining.maxReps,
      repsEspecs: training.userTraining.repsEspecs,
      typeOfRepeating: training.userTraining.typeOfRepeating,
      completed: training.userTraining.completed,
      recurrence: training.userTraining.recurrence,
    }))

    // TODO STORE TRAININGS SELECTED FOR THIS PATIENT

    userTrainingService.updateEditUserTrainings(userTrainingDTOArray).subscribe(() => {})
  }

  return (
    <>
      {openAssignTraining && (
        <Modal open={openAssignTraining}>
          <AssignTrainingFormModal
            onClose={handleCloseAssignTraining}
            setTrainingSelected={setTrainingSelectedWithUserAndNameTraining}
            editTraining={editTraining}
          />
        </Modal>
      )}

      <Box className={styles.form}>
        <Box width={'100%'} display={'flex'} m="1rem">
          <Box id={'Seccion Izquierda'} className={styles.avatarContainer}>
            <AvatarProfile />
            <Box maxWidth={'100%'}>
              <Typography className={styles.userName}>
                {`${patient.firstName} ${patient.lastName}`}
              </Typography>
              <Typography className={styles.roleText}>{t('patient')}</Typography>
            </Box>
          </Box>

          <Box
            display={'flex'}
            flexGrow={1}
            id={'Seccion Derecha'}
            flexDirection={'column'}
            justifyContent={'space-between'}
          >
            <Box className={styles.headerContainer}>
              <Typography className={styles.headerTitle}>{t('trainings')}</Typography>
            </Box>
            <List style={{ display: 'flex', flexDirection: 'column' }}>
              {trainingList.map((value) => {
                const labelId = `checkbox-list-label-${value.userTraining.id}`
                const isSelected =
                  selectedTraining.findIndex(
                    (item) => item.userTraining.id === value.userTraining.id
                  ) !== -1

                return (
                  <ListItem
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="comments"
                        onClick={() => handleEditTrain(value)}
                      >
                        <img src={editIcon} alt="edit" />
                      </IconButton>
                    }
                    key={value.userTraining.id}
                    disablePadding
                  >
                    <ListItemButton
                      role={undefined}
                      onClick={() => handleToggleSelection(value)}
                      dense
                    >
                      <ListItemIcon>
                        <Checkbox
                          style={{ color: 'var(--verde-topbar-y-botones)' }}
                          edge="start"
                          tabIndex={-1}
                          disableRipple
                          checked={isSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <p className={styleList.textTrainingList} id={labelId}>
                        {t(value.title)}
                      </p>
                    </ListItemButton>
                  </ListItem>
                )
              })}
            </List>
            <Box className={styleList.buttonContainer} display="flex">
              <Button
                className={styleList.buttonAdd}
                startIcon={<AddCircleIcon style={{ color: 'var(--verde-topbar-y-botones)' }} />}
                onClick={() => {
                  handleOpenAssignTraining()
                }}
              >
                {t('addTraining')}
              </Button>
              <Button
                className={styleList.buttonRemove}
                startIcon={
                  <img
                    src={cancelIcon}
                    width={'18px'}
                    style={{ marginBottom: 4, marginLeft: 1, padding: 0 }}
                  />
                }
                onClick={() => {
                  handleRemoveTraining()
                }}
              >
                {t('removeTraining')}
              </Button>
            </Box>
            <Box className={styleList.buttonContainer} display="flex">
              <Button className={styleList.buttonSave} onClick={handleSave}>
                {t('save')}
              </Button>
              <Button className={styleList.buttonCancel} onClick={() => navigate(-1)}>
                {t('cancel')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
