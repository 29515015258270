import { UserForm } from '../models/UserForm'
import { Observable } from 'rxjs'
import { IUserFormApi } from '../api/UserFormApi'
import { Container, IInit } from '../../../common/container/Container'
import { ItemList } from '../../../common/models/ItemList'
import { IStatusService } from '../../../common/status/StatusService'
import { Query } from '../../../common/api/Query'
import { UserFormValue } from '../models/UserFormValue'
import {
  TemplateUserRecurrenceDTO,
  UserFormDTO,
  UserFormValueDTO,
  UserFormWithTemplateUserRecurrenceDTO,
} from '../models/UserFormDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { UserFormWithTemplateName } from '../models/UserFormWithTemplateName'
import { UserFormWithTemplateUserRecurrence } from '../models/UserFormWithTemplateUserRecurrence'

type Props = {
  apiKey: symbol
}

export interface IUserFormService extends IInit {
  getByID(id: string): Observable<UserForm | undefined>

  getFilteredList(q: Query<UserForm>): Observable<ItemList<UserForm>>

  getWithTemplateNamesByUserID(userID: string): Observable<ItemList<UserFormWithTemplateName>>

  getListWithTemplateUserRecurrenceByUserID(
    userID: string
  ): Observable<ItemList<UserFormWithTemplateUserRecurrenceDTO>>

  add(
    e: UserForm,
    cron: string,
    startDate: Date,
    finishDate: Date | null
  ): Observable<UserForm | undefined>

  update(e: UserFormDTO): Observable<UserForm | undefined>

  updateValue(e: UserFormValueDTO): Observable<UserFormValue | undefined>

  delete(id: string): Observable<boolean>
}

export class UserFormService implements IUserFormService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IUserFormApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IUserFormApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(
    e: UserForm,
    cron: string,
    startDate: Date,
    finishDate: Date | null
  ): Observable<UserForm | undefined> {
    return this._api.add(e, cron, startDate, finishDate)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<UserForm | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<UserForm>): Observable<ItemList<UserForm>> {
    return this._api.getFilteredList(q).pipe()
  }

  getWithTemplateNamesByUserID(userID: string): Observable<ItemList<UserFormWithTemplateName>> {
    return this._api.getWithTemplateNamesByUserID(userID)
  }

  getListWithTemplateUserRecurrenceByUserID(
    userID: string
  ): Observable<ItemList<UserFormWithTemplateUserRecurrenceDTO>> {
    return this._api.getListWithTemplateUserRecurrenceByUserID(userID)
  }

  getListTemplateUserRecurrenceByUserID(
    userID: string
  ): Observable<ItemList<TemplateUserRecurrenceDTO>> {
    return this._api.getListTemplateUserRecurrenceByUserID(userID)
  }

  addTemplatesUserRecurrenceByUserID(
    userID: string,
    templates: TemplateUserRecurrenceDTO[]
  ): Observable<ItemList<TemplateUserRecurrenceDTO>> {
    return this._api.addTemplatesUserRecurrenceByUserID(userID, templates)
  }

  update(e: UserFormDTO): Observable<UserForm | undefined> {
    return this._api.update(e)
  }

  updateValue(e: UserFormValueDTO): Observable<UserFormValue | undefined> {
    return this._api.updateValue(e)
  }
}
