import { UserForm } from '../models/UserForm'
import { Container, IInit } from '../../../common/container/Container'
import { FormContainerConfig } from '../container'
import { Query } from '../../../common/api/Query'
import { prepareURL } from '../../../common/api/http-helpers'
import { catchError, map } from 'rxjs/operators'
import { Observable, of } from 'rxjs'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import {
  fromModel,
  fromModelTemplateRecurrence,
  TemplateUserRecurrenceDTO,
  toModel,
  toModelValue,
  UserFormDTO,
  UserFormValueDTO,
  UserFormWithTemplateUserRecurrenceDTO,
} from '../models/UserFormDTO'
import {
  toModel as toModelWithTemplateName,
  toModelUserFormWithTemplateUserRecurrence,
} from '../models/UserFormWithTemplateNameDTO'
import { UserFormValue } from '../models/UserFormValue'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { UserFormWithTemplateName } from '../models/UserFormWithTemplateName'
import { UserFormWithTemplateNameDTO } from '../models/UserFormWithTemplateNameDTO'
import { UserFormWithTemplateUserRecurrence } from '../models/UserFormWithTemplateUserRecurrence'
import { TemplateUserRecurrence } from '../models/TemplateUserRecurrence'

export interface IUserFormApi extends IInit {
  getByID(id: string): Observable<UserForm | undefined>

  getFilteredList(q: Query<UserForm>): Observable<ItemList<UserForm>>
  getWithTemplateNamesByUserID(userID: string): Observable<ItemList<UserFormWithTemplateName>>
  getListWithTemplateUserRecurrenceByUserID(
    userID: string
  ): Observable<ItemList<UserFormWithTemplateUserRecurrence>>

  getListTemplateUserRecurrenceByUserID(
    userID: string
  ): Observable<ItemList<TemplateUserRecurrenceDTO>>

  addTemplatesUserRecurrenceByUserID(
    userID: string,
    templates: TemplateUserRecurrenceDTO[]
  ): Observable<ItemList<TemplateUserRecurrenceDTO>>
  add(
    e: UserForm,
    cron: string,
    startDate: Date,
    finishDate: Date | null
  ): Observable<UserForm | undefined>

  update(e: UserFormDTO): Observable<UserForm | undefined>

  updateValue(e: UserFormValueDTO): Observable<UserFormValue | undefined>

  delete(id: string): Observable<boolean>
}

export class UserFormApi implements IUserFormApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as FormContainerConfig).moduleFullUrl + '/user-form'
  }

  getByID(id: string): Observable<UserForm | undefined> {
    return this._httpClient
      .get<UserForm>({
        url: `${this._url}/${id}`,
      })
      .pipe(
        map<UserFormDTO, UserForm>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  add(
    e: UserForm,
    cron: string,
    startDate: Date,
    finishDate: Date
  ): Observable<UserForm | undefined> {
    return this._httpClient
      .post<UserForm>({
        url: this._url,
        body: { ...fromModel(e), cron: cron, startDate: startDate, finishDate: finishDate },
      })
      .pipe(
        map<UserFormDTO, UserForm>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getFilteredList(q: Query<UserForm>): Observable<ItemList<UserForm>> {
    return this._httpClient.get<ItemList<UserForm>>({ url: prepareURL(this._url, q) }).pipe(
      map<ItemList<UserFormDTO>, ItemList<UserForm>>((dto) => {
        const itemList = emptyList<UserForm>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<UserForm>())
      })
    )
  }

  getWithTemplateNamesByUserID(userID: string): Observable<ItemList<UserFormWithTemplateName>> {
    return this._httpClient
      .get<ItemList<UserFormWithTemplateName>>({ url: `${this._url}/user/${userID}` })
      .pipe(
        map<ItemList<UserFormWithTemplateNameDTO>, ItemList<UserFormWithTemplateName>>((dto) => {
          const itemList = emptyList<UserFormWithTemplateName>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModelWithTemplateName(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<UserFormWithTemplateName>())
        })
      )
  }

  getListWithTemplateUserRecurrenceByUserID(
    userID: string
  ): Observable<ItemList<UserFormWithTemplateUserRecurrence>> {
    return this._httpClient
      .get<ItemList<UserFormWithTemplateUserRecurrence>>({
        url: `${this._url}/user-recurrence/${userID}`,
      })
      .pipe(
        map<
          ItemList<UserFormWithTemplateUserRecurrenceDTO>,
          ItemList<UserFormWithTemplateUserRecurrence>
        >((dto) => {
          // console.log('dto', dto)
          const itemList = emptyList<UserFormWithTemplateUserRecurrence>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModelUserFormWithTemplateUserRecurrence(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<UserFormWithTemplateUserRecurrence>())
        })
      )
  }

  getListTemplateUserRecurrenceByUserID(
    userID: string
  ): Observable<ItemList<TemplateUserRecurrenceDTO>> {
    return this._httpClient
      .get<ItemList<TemplateUserRecurrenceDTO>>({
        url: `${this._url}/user-recurrence/${userID}`,
      })
      .pipe(
        map<ItemList<TemplateUserRecurrenceDTO>, ItemList<TemplateUserRecurrenceDTO>>((dto) => {
          const itemList = emptyList<TemplateUserRecurrenceDTO>()
          itemList.count = dto.count
          itemList.items = dto.items
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<TemplateUserRecurrence>())
        })
      )
  }

  addTemplatesUserRecurrenceByUserID(
    userID: string,
    templates: TemplateUserRecurrenceDTO[]
  ): Observable<ItemList<TemplateUserRecurrence>> {
    return this._httpClient
      .post<ItemList<TemplateUserRecurrence>>({
        url: `${this._url}/user-recurrence/${userID}`,
        body: { recurrences: templates },
      })
      .pipe(
        map<ItemList<TemplateUserRecurrenceDTO>, ItemList<TemplateUserRecurrence>>((dto) => {
          // console.log('dto', dto)
          const itemList = emptyList<TemplateUserRecurrence>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => new TemplateUserRecurrence(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<TemplateUserRecurrence>())
        })
      )
  }

  update(e: UserFormDTO): Observable<UserForm | undefined> {
    return this._httpClient.put<UserForm>({ url: this._url, body: e }).pipe(
      map<UserFormDTO, UserForm>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  updateValue(e: UserFormValueDTO): Observable<UserFormValue | undefined> {
    return this._httpClient.put<UserFormValue>({ url: this._url + '-value', body: e }).pipe(
      map<UserFormValueDTO, UserFormValue>((d) => {
        return toModelValue(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }
}
