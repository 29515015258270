import { Box, Typography } from '@mui/material'
import { RouteProps } from 'routes/AppRouter'
import genericStyle from 'common/utils/generic.module.css'
import { useTranslation } from 'react-i18next'
import React, { ChangeEvent, useEffect, useState } from 'react'
import styles from './ProfileTab.module.scss'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { emptyUserDTO, UserDTO } from 'modules/users/models/User'
import AvatarProfile from 'components/avatar-profile/AvatarProfile'
import { Input } from 'features/resource/userProfileStylesMui'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { ROUTE_PATIENTS, ROUTE_USERS_CREATE } from 'routes/routes-constants'
import { getUserContainer } from 'container/user-module'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from 'modules/users'
import { getAppContainer, STATUS_SERVICE_KEY } from 'container/app'
import { IStatusService } from 'common/status/StatusService'
import { PATIENT_ROLE_ID } from 'features/users-new/constants'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import dayjs from 'dayjs'
import { CalendarIcon } from 'features/calendar/Calendar'

interface ProfileTabProps extends RouteProps {
  patient?: UserDTO
}

const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const statusService = getAppContainer().get<IStatusService>(STATUS_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export const ProfileTab = ({ patient, id }: ProfileTabProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const loggedUser = loggedUserService.get()
  const [newPatient, setNewPatient] = useState<UserDTO>(emptyUserDTO())

  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false)
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')

  useEffect(() => {
    if (patient) {
      setNewPatient(patient)
    }
  }, [patient])

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault()
    if (!id) {
      userService.add(newPatient).subscribe((data) => {
        if (!data) return
        statusService.sendStatus({ variant: 'success' })
        navigate(`${ROUTE_USERS_CREATE}/${data.id}`)
      })
    } else {
      userService.update(newPatient).subscribe(() => {
        statusService.sendStatus({ variant: 'success' })
      })
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target
    const updatedPatient = {
      ...newPatient,
      [id]: value,
      rolesIds: [PATIENT_ROLE_ID],
      isPatient: false,
      assignedID: loggedUser?.id ?? '',
    }
    setNewPatient(updatedPatient)
  }

  const handleDateChange = (date: any) => {
    const updatedPatient = {
      ...newPatient,
      dobv: date.toISOString(),
      rolesIds: [PATIENT_ROLE_ID],
      isPatient: false,
      assignedID: loggedUser?.id ?? '',
    }
    setNewPatient(updatedPatient)
  }

  return (
    <form className={styles.form} id={'editUser'}>
      <Box width={'100%'} display={'flex'} bgcolor="transparent" m="1rem">
        <Box id={'Seccion Izquierda'} className={styles.avatarContainer}>
          <AvatarProfile />
          <Box maxWidth={'100%'}>
            <Typography className={styles.userName}>
              {`${newPatient.firstName} ${newPatient.lastName}`}
            </Typography>
            <Typography className={styles.roleText}>{t('patient')}</Typography>
          </Box>
        </Box>
        <Box
          display={'flex'}
          flexGrow={1}
          id={'Seccion Derecha'}
          flexDirection={'column'}
          justifyContent={'space-between'}
        >
          {/* Aqui va el form */}
          <Box id={'Patient Form'}>
            <Box style={{ maxWidth: '100%' }} className={styles.headerContainer}>
              <Typography className={styles.headerTitle}>{t('userData')}</Typography>
            </Box>
            <Box mb={1} className={styles.textFieldBox}>
              <Box marginRight={'20px'} width={'50%'}>
                <Typography className={styles.inputTitle}>{t('name')}</Typography>
                <Input
                  id={'firstName'}
                  label=""
                  key={'firstName'}
                  name="firstName"
                  className={styles.textField}
                  value={newPatient.firstName}
                  onChange={handleChange}
                  type="text"
                  required={true}
                  variant={'outlined'}
                  size={'small'}
                />
              </Box>
              <Box marginRight={'20px'} width={'50%'}>
                <Typography className={styles.inputTitle}>{t('lastName')}</Typography>
                <Input
                  id={'lastName'}
                  label={''}
                  key={'lastName'}
                  name="lastName"
                  className={styles.textField}
                  value={newPatient.lastName}
                  onChange={handleChange}
                  type="text"
                  required={true}
                  variant={'outlined'}
                  size={'small'}
                />
              </Box>
            </Box>
            <Box mb={1} className={styles.textFieldBox}>
              <Box marginRight={'20px'} width={'50%'}>
                <Typography className={styles.inputTitle}>{t('birthDate')}</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                  <DatePicker
                    sx={{ width: '100%' }}
                    key={'dob'}
                    name="dob"
                    value={patient && patient.dob ? dayjs(patient.dob) : dayjs(new Date())}
                    open={isDatePickerOpen}
                    onOpen={() => setIsDatePickerOpen(true)}
                    onClose={() => setIsDatePickerOpen(false)}
                    format={'DD/MM/YYYY'}
                    onChange={(e) => {
                      handleDateChange(e as unknown as Date)
                    }}
                    label={''}
                    onError={(reason) => {
                      switch (reason) {
                        case 'invalidDate':
                          setDateTimePickerError(t('invalidDateMessage'))
                          break
                        case 'minDate':
                          setDateTimePickerError(t('minDateMessage'))
                          break
                      }
                    }}
                    slotProps={{
                      textField: {
                        id: 'dob',
                        size: 'small',
                        InputProps: {
                          sx: {
                            width: '100%',
                            marginRight: '20px',
                            '& fieldset': {
                              borderRadius: 32,
                              border: '1px solid #E8E7EC',
                              fontFamily: 'Montserrat-Regular',
                            },
                            '&.MuiInputBase-root': { fontFamily: 'Montserrat-Regular' },
                            '&.MuiOutlinedInput-root': {
                              border: 'none',
                              '&.Mui-focused fieldset': {
                                borderColor: '#2E7161',
                              },
                            },
                          },
                          onClick: () => setIsDatePickerOpen(true),
                        },
                      },
                    }}
                    slots={{
                      openPickerIcon: CalendarIcon,
                    }}
                  />
                </LocalizationProvider>
                {DateTimePickerError && (
                  <Typography className={styles.errorText}>{DateTimePickerError}</Typography>
                )}
              </Box>
              <Box marginRight={'20px'} width={'50%'}>
                <Typography className={styles.inputTitle}>{t('role')}</Typography>
                <Typography className={styles.typoText} sx={{ paddingLeft: '14px' }}>
                  {t('patient')}
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* Fin del form */}
          <Box id={'Form y Buttons'} display="flex" justifyContent={'flex-end'} mb={4}>
            <Box
              className={styles.buttonContainer}
              display="flex"
              paddingLeft="40px"
              paddingBottom="5px"
            >
              <Button className={styles.buttonSubmit} type={'button'} onClick={handleSave}>
                {t('save')}
              </Button>
              <Button
                className={styles.buttonCancel}
                type={'button'}
                onClick={() => navigate(ROUTE_PATIENTS)}
              >
                {t('cancel')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </form>
  )
}
