import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getUserContainer } from 'container/user-module'
import { IUserService, USER_SERVICE_KEY } from 'modules/users'
import { emptyUserDTO, fromModel, UserDTO } from 'modules/users/models/User'
import { Box } from '@mui/material'
import genericStyle from 'common/utils/generic.module.css'
import { useIsOpenMenuLeftContext } from 'common/utils/isOpenMenuLeftContext'
import { CreateEditParticipantProps } from 'pages/users/EditParticipant'
import { UserNewProfessional } from './professional/professional'
import { UserNewPatient } from './patient/patient'

const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
// const circleService = getCircleContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)

export function UsersNewsEditor(props: CreateEditParticipantProps) {
  const { id } = useParams()
  const [userEdit, setUserEdit] = useState<UserDTO>(emptyUserDTO())

  useEffect(() => {
    if (!id) return

    userService.getByID(id).subscribe((response) => {
      if (!response) return
      setUserEdit(fromModel(response))
    })
  }, [id])

  const { openMenuLeft } = useIsOpenMenuLeftContext()

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: openMenuLeft ? '50px' : '',
      }}
    >
      <Box
        style={{
          maxWidth: openMenuLeft ? 'calc(100% - 250px)' : 'calc(100% - 300px)',
          minWidth: '1040px',
        }}
        className={genericStyle.pageContainer}
      >
        {props.isProfessional && <UserNewProfessional id={id} professional={userEdit} />}
        {!props.isProfessional && <UserNewPatient id={id} patient={userEdit} />}
      </Box>
    </div>
  )
}
