import { UserDTO } from '../../users/models/User'

export type CircleWithPatientProps = {
  id: string | undefined
  patient: UserDTO
  familiars: string[] | undefined
}

export class CircleWithPatient {
  private readonly _id: string | undefined
  private readonly _patient: UserDTO
  private readonly _familiars: string[] | undefined

  constructor(p: CircleWithPatientProps) {
    this._id = p.id
    this._patient = p.patient
    this._familiars = p.familiars
  }

  get id(): string | undefined {
    return this._id
  }

  get patient(): UserDTO {
    return this._patient
  }

  get familiars(): string[] | undefined {
    return this._familiars
  }
}

export interface CircleWithPatientQuery {
  patient?: string
}
