import { Box, MenuItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Header } from 'components/header/Header'
import styles from './ConductForm.module.scss'
import { useTranslation } from 'react-i18next'
import { Input } from '../../resource/userProfileStylesMui'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import { FormType } from 'modules/forms/enums/FormType'

interface ConductFormModalProps {
  handleRecurrence: (recurrence?: string) => void
  userNumberTimesDays?: number | string | undefined
  userFrequency?: number | string | undefined
  onClose: () => void
}

export const ConductFormModal: React.FC<ConductFormModalProps> = ({
  handleRecurrence,
  userNumberTimesDays,
  userFrequency,
  onClose,
}) => {
  const { t } = useTranslation()
  const numberTimesDaysArray = [' ', 1, 2, 3, 6]
  const frequencyArray = [' ', 4, 8, 12, 24]
  const [numberTimesDays, setNumberTimesDays] = useState<number | string | undefined>(
    userNumberTimesDays ?? undefined
  )
  const [frequency, setFrequency] = useState<number | string | undefined>(
    userFrequency ?? undefined
  )
  const startHour = 7

  const handlerNumberTimesDays = (e: any) => {
    setNumberTimesDays(e.target.value)
    setFrequency('') // Reset frequency when numberTimesDays is selected
  }

  const handlerFrequency = (e: any) => {
    setFrequency(e.target.value)
    setNumberTimesDays('') // Reset numberTimesDays when frequency is selected
  }

  const saveRecurrence = () => {
    const base = '0 0 0 0'
    let recurrence = '0 0 0 0' // Valor por defecto

    if (numberTimesDays && numberTimesDays !== ' ') {
      const hours = 24 / Number(numberTimesDays)
      const endHour = startHour + hours
      if (endHour > 23) {
        recurrence = `0 ${startHour}-23/${hours} * * *`
      } else {
        recurrence = `0 ${startHour}-${endHour}/${hours} * * *`
      }
    } else if (frequency && frequency !== ' ') {
      const endHour = startHour + Number(frequency)
      if (endHour > 23) {
        recurrence = `0 ${startHour}-23/${frequency} * * *`
      } else {
        recurrence = `0 ${startHour}-${endHour}/${frequency} * * *`
      }
    }
    handleRecurrence(base !== recurrence ? recurrence : undefined)
    onClose()
  }
  return (
    <Box className={styles.container}>
      <Box className={styles.content}>
        <Header label={t('conductForm')} />
        <Box width={'80%'}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <p className={styles.title}>{t('numberTimesDays')} </p>
            <Input
              select
              sx={{ width: '30%' }}
              value={numberTimesDays}
              disabled={!!frequency && frequency !== ' '}
              onChange={handlerNumberTimesDays}
            >
              {numberTimesDaysArray.map((item) => (
                <MenuItem style={{ minHeight: 28 }} key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Input>
          </Box>
          <Box mt={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <p className={styles.title}>{t('frequencyHours')}</p>
            <Input
              disabled={!!numberTimesDays && numberTimesDays !== ' '}
              select
              sx={{ width: '30%' }}
              value={frequency}
              onChange={handlerFrequency}
            >
              {frequencyArray.map((item) => (
                <MenuItem style={{ minHeight: 28 }} key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Input>
          </Box>
        </Box>
        <Box mt={8} className={styles.buttons} display="flex" justifyContent="center">
          <Box style={{ marginRight: 10 }}>
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'submit'}
              label={t('save')}
              handler={saveRecurrence}
            />
          </Box>
          {/* <AppButton
            theme={ButtonTheme.NewSecondary}
            type={'button'}
            label={t('cancel')}
            handler={onClose}
          /> */}
        </Box>
      </Box>
    </Box>
  )
}
