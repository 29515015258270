import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react'
import {
  CircleWithPatientDTO,
  emptyCircleWithPatientDTO,
} from '../../modules/circle/models/CircleWithPatientDTO'
import { CIRCLE_KEY } from '../../modules/users/services/LoggedUserService'

export type CircleContextType = {
  circle: CircleWithPatientDTO | undefined
  setCircle: (c: CircleWithPatientDTO | undefined) => void
}

export const CircleContext = createContext<CircleContextType>({
  circle: undefined,
  setCircle: () => {},
})

export const useCircleContext = () => useContext(CircleContext)

export const CircleProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [circle, setCircle] = useState<CircleWithPatientDTO | undefined>(undefined)
  const getCircle = () => {
    const circleData = localStorage.getItem(CIRCLE_KEY)
    if (circleData) {
      const json = JSON.parse(circleData)
      const newCircle: CircleWithPatientDTO = {
        id: json._id,
        patient: json._patient,
        familiars: json._familiars,
      }
      setCircle(newCircle)
    }
  }
  useEffect(() => {
    getCircle()
  }, [])

  const handleSetCircle = (newCircle: CircleWithPatientDTO | undefined) => {
    setCircle(newCircle)
    if (newCircle) {
      localStorage.setItem(CIRCLE_KEY, JSON.stringify(newCircle))
    } else {
      localStorage.removeItem(CIRCLE_KEY)
    }
  }

  return (
    <CircleContext.Provider value={{ circle, setCircle: handleSetCircle }}>
      {children}
    </CircleContext.Provider>
  )
}
